import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


export default function DashboardParaBusinessDevelopers({ data, dataUser, loading }) {

    const { t } = useTranslation();


    return (
        <>

          
        </>
    )
};
