import React, { useState } from 'react';
import axios from 'axios';
import { Input, Button, Select, Form, message } from 'antd';

const { Option } = Select;

export default function RegistarModeloCalculoProposta_ISV_IUC_ES({ setIsModalOpenModelo, setDataListas, dataListas, dataUser, setIsvIucData, setTriggerCalculo }) {

    const [form] = Form.useForm();

    const handleRegistarModelo = (values) => {

        const dataCriarModelo = {
            nome_modelo: values.nomeModelo,
            id_marca: values.idMarca,
            inicio: values.inicio,
            fim: values.fim,
            cilindrada: values.cilindrada,
            combustivel: values.combustivel,
            kw: values.kw,
            cvf: values.cvf,
            co2: values.co2,
            cavalos: values.cavalos,
            valor_novo: values.valorViaturaNova,
            cilindros: values.cilindros,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
        };

        axios.post(process.env.REACT_APP_REGISTAR_MODELO_ES, dataCriarModelo)
            .then(response => {
                // console.log(response)
                if (response.data.sucesso) {

                    const novoModelo = {
                        nome_modelo: values.nomeModelo,
                        id_marca: values.idMarca,
                        id_modelo: response.data.mensagem,
                        inicio: values.inicio,
                        fim: values.fim,
                        cilindrada: values.cilindrada,
                        combustivel: values.combustivel,
                        kw: values.kw,
                        cvf: values.cvf,
                        co2: values.co2,
                        cavalos: values.cavalos,
                        valor_novo: values.valorViaturaNova,
                        cilindros: values.cilindros,
                    };

                    setDataListas((prevData) => {
                        const prevModelos = prevData.listaModelos || [];
                        return {
                            ...prevData,
                            listaModelos: [novoModelo, ...prevModelos],
                        };
                    });


                    setIsvIucData((prevData) => ({
                        ...prevData,
                        valorViaturaNova: values.valorViaturaNova,
                        idModelo: response.data.mensagem,
                        nomeModelo: values.nomeModelo,
                        emissoesCO2: values.co2,
                    }));

                    setTriggerCalculo((prevData) => ({
                        ...prevData,
                        valorViaturaNova: values.valorViaturaNova,
                        idModelo: response.data.mensagem,
                        nomeModelo: values.nomeModelo,
                        emissoesCO2: values.co2
                    }));


                    message.success('¡Modelo registrado con éxito!');
                    setIsModalOpenModelo(false)
                    form.resetFields();
                }
            })
            .catch(error => {
                console.error(error);
                message.error('Error al registrar el modelo.');
            });
    };


    return (
        <Form
            form={form}
            layout="vertical"
            className="form-de-envio-docs"
            onFinish={handleRegistarModelo}
        >
            <div className="grid gap-2 content-start">
                <p>Nombre del modelo</p>
                <Form.Item name="nomeModelo" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input placeholder="Nombre del modelo" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Marca</p>
                <Form.Item name="idMarca" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        showSearch
                        placeholder="Selecciona una marca"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {

                            const marcaSeleccionada = dataListas.listaMarcas.find(
                                (marca) => marca.id_marca === value
                            );


                            setIsvIucData((prevData) => ({
                                ...prevData,
                                nomeMarca: marcaSeleccionada.nome_marca
                            }));

                            setTriggerCalculo((prevData) => ({
                                ...prevData,
                                nomeMarca: marcaSeleccionada.nome_marca
                            }));

                        }}
                    >
                        {dataListas.listaMarcas.map((marca) => (
                            <Option key={marca.id_marca} value={marca.id_marca}>
                                {marca.nome_marca}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Inicio de la producción</p>
                <Form.Item name="inicio" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input placeholder="Inicio de la producción" type="number" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Fin de producción</p>
                <Form.Item name="fim">
                    <Input placeholder="Fin de producción" type="number" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Cilindrada</p>
                <Form.Item name="cilindrada" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input placeholder="Cilindrada" type="number" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Tipo de Combustible</p>
                <Form.Item name="combustivel" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select placeholder="Seleccione">
                        <Option value="">Seleccione</Option>
                        <Option value="Gasolina">Gasolina</Option>
                        <Option value="Gasoleo">Gasóleo</Option>
                        <Option value="Eletrico">Eléctrico</Option>
                        <Option value="Gasolina/Gasolina GLP">Gasolina/Gasolina GLP</Option>
                        <Option value="Híbrido (Gasóleo/Eletrico)">Híbrido (Gasóleo/Eléctrico)</Option>
                        <Option value="Híbrido (Gasolina/Eletrico)">Híbrido (Gasolina/Eléctrico)</Option>
                    </Select>
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>CO2</p>
                <Form.Item name="co2">
                    <Input placeholder="CO2" type="number" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>KW</p>
                <Form.Item name="kw">
                    <Input placeholder="KW" type="number" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>CVF - <a className="underline hover:text-black hover:underline" href="https://www.seisenlinea.com/calcular-caballos-fiscales/" target="_blank" rel="noopener noreferrer">
                    Calcular caballos fiscales
                </a></p>
                <Form.Item name="cvf" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input placeholder="CVF" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Cavalos</p>
                <Form.Item name="cavalos">
                    <Input placeholder="Cavalos" type="number" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Valor Novo</p>
                <Form.Item name="valorViaturaNova" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input placeholder="35000" type="number" />
                </Form.Item>
            </div>

            <div className="grid gap-2 content-start">
                <p>Cilindros</p>
                <Form.Item name="cilindros" rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input placeholder="Cilindros" type="number" />
                </Form.Item>
            </div>

            <Button type="primary" htmlType="submit" className="shadow-none font-bold text-black bg-green-400">Registrar Modelo</Button>
        </Form>
    );
}
