import React from 'react';

export default function ResultadoTaxas({ taxaDeJuroCredito, tipoDeTaxa }) {
    return (
        <div className="flex justify-between gap-4 justify-items-center text-white">
            <div>
                <p className="flex items-center gap-2">
                    <span className="text-sm">TAN</span>
                </p>
                <p><span className="text-sm font-bold">{taxaDeJuroCredito ? `${taxaDeJuroCredito}%` : ``}</span></p>
            </div>
            <div>
                <p className="flex items-center gap-2 justify-center">
                    <span className="text-sm">TAEG</span>
                </p>
                <p><span className="text-sm font-bold">{taxaDeJuroCredito ? `${taxaDeJuroCredito}%` : ``}</span></p>
            </div>
            <div>
                <p className="flex items-center gap-2 justify-center">
                    <span className="text-sm">Tipo de Taxa</span>
                </p>
                <p><span className="text-sm font-bold">{tipoDeTaxa || 'Fixa'}</span></p>
            </div>
        </div>
    );
}
