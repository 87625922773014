import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
    FileTextOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-branco 2.svg';
import logoIcon from '../assets/logo-icon-branco 2.svg';
import { useTranslation } from 'react-i18next';
import { GoTools } from "react-icons/go";
import { FaHubspot } from 'react-icons/fa6';
import { PiHouse } from "react-icons/pi";
import { LuUsers } from "react-icons/lu";
import { HiRefresh } from 'react-icons/hi';

const { Sider } = Layout;

export default function CustomSidebar({ collapsed, dataUser }) {
    const { t } = useTranslation();
    const [openKey, setOpenKey] = useState(null);

    // Definir os itens do menu
    const menuItems = [
        {
            key: '1',
            icon: <PiHouse />,
            label: <Link to="/">{t('menu.painel')}</Link>,
        },
        {
            key: 'propostas',
            icon: <FileTextOutlined />,
            label: t('menu.propostas'),
            children: [
                {
                    key: '2',
                    label: (
                        <Link to={`/${t('menu.gerar_proposta')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .replace(/ /g, '-')}`}>
                            {t('menu.gerar_proposta')}
                        </Link>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <Link to={`/${t('menu.ver_propostas')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .replace(/ /g, '-')}`}>
                            {t('menu.ver_propostas')}
                        </Link>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <Link to={`/${t('menu.historico')
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .replace(/ /g, '-')}`}>
                            {t('menu.historico')}
                        </Link>
                    ),
                },
            ],
        },
        {
            key: '5',
            icon: <FaHubspot />,
            label: (
                <Link to={`/${t('menu.hubspot')
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/ /g, '-')}`}>
                    {t('menu.hubspot')}
                </Link>
            ),
        },
        {
            key: '6',
            icon: <HiRefresh />,
            label: (
                <Link to={`/${t('menu.automacoes')
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/ /g, '-')}`}>
                    {t('menu.automacoes')}
                </Link>
            ),
        },
        ...(dataUser.language === 'pt'
            ? [
                {
                    key: 'documentos',
                    icon: <GoTools />,
                    label: t('menu.ferramentas'),
                    children: [
                        // ...(dataUser.role === 'administrator'
                        //     ? [
                        //         {
                        //             key: '7',
                        //             label: (
                        //                 <Link to={`/${t('menu.gerar_documentos')
                        //                     .toLowerCase()
                        //                     .normalize('NFD')
                        //                     .replace(/[\u0300-\u036f]/g, '')
                        //                     .replace(/ /g, '-')}`}>
                        //                     {t('menu.gerar_documentos')}
                        //                 </Link>
                        //             ),
                        //         },
                        //     ]
                        //     : []),
                        {
                            key: '7',
                            label: (
                                <Link to={`/${t('menu.gerar_documentos')
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .replace(/ /g, '-')}`}>
                                    {t('menu.gerar_documentos')}
                                </Link>
                            ),
                        },
                        {
                            key: '8',
                            label: (
                                <Link to={`/${t('menu.ver_documentos')
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .replace(/ /g, '-')}`}>
                                    {t('menu.ver_documentos')}
                                </Link>
                            ),
                        },
                        {
                            // ...(dataUser.iDTokenAuthenticated === 1 ? [{
                            key: '9',
                            label: (
                                <Link to={`/${t('menu.simulador_isv')
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .replace(/ /g, '-')}`}>
                                    {t('menu.simulador_isv')}
                                </Link>
                            ),
                        },
                        {
                            // }] : []),
                            // ...(dataUser.iDTokenAuthenticated === 1 ? [{
                            key: '10',
                            label: (
                                <Link to={`/${t('menu.simulador_credito')
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .replace(/ /g, '-')}`}>
                                    {t('menu.simulador_credito')}
                                </Link>
                            ),
                            // }] : []),
                        },
                        {
                            key: '11',
                            label: (
                                <Link to={`/${t('menu.gerar_assinatura')
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .replace(/ /g, '-')}`}>
                                    {t('menu.gerar_assinatura')}
                                </Link>
                            ),
                        },
                        // {
                        //     key: '12',
                        //     label: (
                        //         <Link to={`/${t('menu.ferramentas_apoio')
                        //             .toLowerCase()
                        //             .normalize('NFD')
                        //             .replace(/[\u0300-\u036f]/g, '')
                        //             .replace(/ /g, '-')}`}>
                        //             {t('menu.ferramentas_apoio')}
                        //         </Link>
                        //     ),
                        // },
                    ],
                },
            ]
            : []),

        ...(dataUser.role === 'administrator'
            ? [
                {
                    key: 'utilizadores',
                    icon: <LuUsers />,
                    label: t('menu.utilizadores'),
                    children: [
                        {
                            key: '13',
                            label: (
                                <Link to="/lista-de-utilizadores">{t('menu.lista_utilizadores')}</Link>
                            ),
                        },
                        // {
                        //     key: '11',
                        //     label: (
                        //         <Link to="/registar-utilizador">{t('menu.registar_utilizadores')}</Link>
                        //     ),
                        // },
                    ],
                },
            ]
            : []),
    ];

    return (
        <Sider
            className="card-sidebar sidebar"
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={{ position: 'sticky', top: 0 }}
        >
            <div className="flex justify-center p-2 mt-2 mb-2">
                <Link to="/">
                    <img
                        src={collapsed ? logoIcon : logo}
                        alt="Logo"
                        className="w-40 max-w-40 h-10 object-contain cursor-pointer"
                    />
                </Link>
            </div>

            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                openKeys={[openKey]}
                onOpenChange={(keys) => {
                    const latestOpenKey = keys.find((key) => key !== openKey);
                    setOpenKey(latestOpenKey);
                }}
                className='text-xs font-bold'
                items={menuItems} // Usando a prop 'items' para passar os itens do menu
            />
        </Sider>
    );
}
