import React from 'react';
import { BrowserRouter } from 'react-router-dom'; // Gerencia as rotas
import { createRoot } from 'react-dom/client'; // API de renderização do React 18
import App from './App';
import './styles.css'; // Importa estilos globais
import './styles/index.css'; // Importa outros estilos
import './i18n/i18n'; // Configuração de internacionalização (i18n)

import { I18nextProvider } from 'react-i18next'; // Provedor para i18n
import i18n from './i18n/i18n'; // Instância configurada do i18n

// Criação da raiz para renderizar o React 18+
const root = createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode> // Desativado para evitar o duplo uso do useEffect em desenvolvimento
    <I18nextProvider i18n={i18n}> {/* Provedor de i18n para internacionalização */}
      <BrowserRouter> {/* Provedor de roteamento para o React Router */}
        <App /> {/* Componente principal da aplicação */}
      </BrowserRouter>
    </I18nextProvider>
  // </React.StrictMode> // React StrictMode é útil para identificar problemas em dev
);
