import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Tabs } from 'antd';
import { IoAlertCircleOutline } from "react-icons/io5";


const { TabPane } = Tabs;

//icon loading
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 50,
        }}
        spin
    />
);


export default function CompGerarPDF({ checkedValue, setActiveTabStepTresKey, activeTabStepTresKey, tipoDocumento, urlPDFGerado, loading, mensagemDeErroAoGerar, tipoContrato }) {

    const handleTabsChange = (key) => {
        setActiveTabStepTresKey(key);
    };

    const handleTabs1Change = (key) => {
        setActiveTabStepTresKey(key);
    };

    const handleTabs2Change = (key) => {
        setActiveTabStepTresKey(key);
    };


    // console.log(activeTabStepTresKey)



    // console.log(urlPDFGerado)
    return (
        <>
            {loading ? (
                <div className='card flex items-center gap-5 flex-col justify-center text-lg rounded-md p-2.5'>
                    <Spin indicator={antIcon} />
                    <p className='text-white'>A gerar...</p>
                </div>
            ) : mensagemDeErroAoGerar ? (
                <>
                    <div className='card flex items-center gap-5 flex-col justify-center text-lg rounded-md p-2.5'>
                        <IoAlertCircleOutline className="text-4xl" />
                        <span className="text-base font-medium">Alerta</span>
                        <p className="text-lg text-white font-medium text-center">{mensagemDeErroAoGerar}</p>
                    </div>
                </>
            ) : (
                <>
                    {urlPDFGerado ?
                        (tipoDocumento === "novo_negocio" ? (
                            <>
                                <Tabs activeKey={activeTabStepTresKey} onChange={handleTabsChange}>
                                    <TabPane tab="Procuração" key="1">
                                        <div>
                                            <iframe
                                                src={`https://${urlPDFGerado.procuracao}`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Contrato" key="2">
                                        <div>
                                            <iframe
                                                src={`https://${urlPDFGerado.contrato}`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </TabPane>
                                    {tipoContrato !== "legalizacao" && (
                                        <TabPane tab="Autorização de Levantamento" key="3">
                                            <div>
                                                <iframe
                                                    src={`https://${urlPDFGerado.autorizacao_levantamento}`}
                                                    title="PDF Viewer"
                                                    width="100%"
                                                    height="600px"
                                                    style={{ border: 'none' }}
                                                ></iframe>
                                            </div>
                                        </TabPane>
                                    )}
                                    <TabPane tab="Modelo 9" key="4">
                                        <div>
                                            <iframe
                                                src={`https://${urlPDFGerado.modelo_9}`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Checklist Chegada" key="5">
                                        <div>
                                            <iframe
                                                src={`https://${urlPDFGerado.checklist_chegada}`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </TabPane>
                                    {checkedValue === 1 && (
                                        <TabPane tab="Documento Seguro" key="6">
                                            <div>
                                                <iframe
                                                    src={`https://${urlPDFGerado.seguro}`}
                                                    title="PDF Viewer"
                                                    width="100%"
                                                    height="600px"
                                                    style={{ border: 'none' }}
                                                ></iframe>
                                            </div>
                                        </TabPane>
                                    )}
                                </Tabs>
                            </>
                        ) : tipoDocumento === "modelo_9" ? (
                            <>
                                <Tabs activeKey={activeTabStepTresKey} onChange={handleTabs1Change}>
                                    <TabPane tab="Modelo 9" key="1">
                                        <div>
                                            <iframe
                                                src={`https://${urlPDFGerado.modelo_9}`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Checklist Chegada" key="2">
                                        <div>
                                            <iframe
                                                src={`https://${urlPDFGerado.checklist_chegada}`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </>
                        ) : (
                            <>

                                <Tabs activeKey={activeTabStepTresKey} onChange={handleTabs2Change}>
                                    <TabPane tab="Documento" key="1">
                                        <div>
                                            <iframe
                                                src={`https://${urlPDFGerado[tipoDocumento]}`}
                                                title="PDF Viewer"
                                                width="100%"
                                                height="600px"
                                                style={{ border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </TabPane>
                                    {checkedValue === 1 && (
                                        <TabPane tab="Documento Seguro" key="2">
                                            <div>
                                                <iframe
                                                    src={`https://${urlPDFGerado.seguro}`}
                                                    title="PDF Viewer"
                                                    width="100%"
                                                    height="600px"
                                                    style={{ border: 'none' }}
                                                ></iframe>
                                            </div>
                                        </TabPane>
                                    )}
                                </Tabs>
                            </>
                        )) : (
                            <>
                                <p>O tipo de documento <strong>"{tipoDocumento}"</strong> ainda não está pronto.</p>
                            </>
                        )}

                </>
            )}
        </>
    )

}
