import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/Elements/Title';
import TableTodasAutomacoes from '../../components/Automacoes/TableTodasAutomacoes';

export default function TodasAutomacoes({ dataUser }) {

    const { t } = useTranslation();


    
    return (
        <div className='card grid gap-4'>
            <TitleSection
                title={t('automacoes.titulo_principal')}
                description={t('automacoes.sub_titulo')}
            />
            <TableTodasAutomacoes dataUser={dataUser} /> 
        </div>
    );
};

