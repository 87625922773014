import React, { useState } from "react";
import TitleSection from "../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import CalculadoraCredito from "../../components/SimuladorDeCredito/CalculadoraCredito";


export default function SimuladorDeCredito({ }) {

    const { t } = useTranslation();

    return (
        <>
            <div className='card grid gap-4'>
                <TitleSection
                    title={t('simulador_credito.titulo_principal')}
                    description={t('simulador_credito.sub_titulo')}
                />
            </div>
            <CalculadoraCredito />
        </>
    );
}