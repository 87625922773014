import React, { useState } from 'react';
import { Modal } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const IUCComponent = ({ valoresTotais, dataUser, formatarNumero }) => {

    const { t } = useTranslation();

    const [isModalVisiblePT, setIsModalVisiblePT] = useState(false);
    const [isModalVisibleES, setIsModalVisibleES] = useState(false);

    // const formatarOuMostrarSemValor = (valor) => {
    //     return valor === '' || valor === 0 ? 'S/V' : formatarNumero(valor);
    // };

    const showModalPT = () => {
        setIsModalVisiblePT(true);
    };

    const handleOkPT = () => {
        setIsModalVisiblePT(false);
    };

    const handleCancelPT = () => {
        setIsModalVisiblePT(false);
    };

    const showModalES = () => {
        setIsModalVisibleES(true);
    };

    const handleOkES = () => {
        setIsModalVisibleES(false);
    };

    const handleCancelES = () => {
        setIsModalVisibleES(false);
    };

    return (
        <>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                    <p className='text-black text-base'>{t('gerar_proposta.iuc')}</p>
                    {valoresTotais.valorTotalIUC !== 0 && (
                        <>
                            {dataUser.language == "pt" && (
                                <AiOutlineInfoCircle className='icon-question' onClick={showModalPT} />
                            )}
                            {dataUser.language == "es" && (
                                <AiOutlineInfoCircle className='icon-question' onClick={showModalES} />
                            )}
                        </>
                    )}
                </div>

                <p className='font-bold text-lg'>
                    {valoresTotais.valorTotalIUC == 0 ? (
                        t('gerar_proposta.isento')
                    ) : (
                        `${formatarNumero(valoresTotais.valorTotalIUC)}`
                    )}
                </p>

            </div>

            {/* PT */}
            <Modal
                title="Explicação do cálculo IUC"
                open={isModalVisiblePT}
                onOk={handleOkPT}
                onCancel={handleCancelPT}
                okText="Fechar"
                footer={null}
            >
                <p className="text-black text-sm flex justify-between">Cilindrada: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucCilindrada ? `${formatarNumero(valoresTotais.compsIUC.iucCilindrada)}` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">CO2: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucCO2 ? `${formatarNumero(valoresTotais.compsIUC.iucCO2)}` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">Adicional CO2: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucAdicionalCO2 ? `${formatarNumero(valoresTotais.compsIUC.iucAdicionalCO2)}` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">Coeficiente ano matricula: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucCoeficienteAnoMatriculo ? `${valoresTotais.compsIUC.iucCoeficienteAnoMatriculo}` : "Sem informação"}</span></p>
                <p className="text-black text-sm flex justify-between">Adicional Gasóleo: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucAdicionalGasoleo ? `${formatarNumero(valoresTotais.compsIUC.iucAdicionalGasoleo)}` : "Sem informação"}</span></p>
                <div className="border-t-4 pt-2 mt-2">
                    <p className="text-black text-sm flex justify-between">O IUC é pago anualmente pelo proprietário do veículo. A primeira guia de IUC tem de ser paga dentro dos 90 dias após emissão da DAV.</p>
                </div>
            </Modal>

            {/* ES */}
            <Modal
                title="Explicación del cálculo IVTM"
                open={isModalVisibleES}
                onOk={handleOkES}
                onCancel={handleCancelES}
                okText="Fechar"
                footer={null}
            >
                <p className="text-black text-sm flex justify-between">Caballos fiscales: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucCavalosFiscais ? `${formatarNumero(valoresTotais.compsIUC.iucCavalosFiscais)}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Ciudad: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucCidade ? `${valoresTotais.compsIUC.iucCidade}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Municipio: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucMunicipio ? `${valoresTotais.compsIUC.iucMunicipio}` : "Sin información"}</span></p>
                <p className="text-black text-sm flex justify-between">Porcentaje de descuento eléctrico: <span className="font-bold text-sm">{valoresTotais.compsIUC.iucPercentagemDescontoEletrico ? `${valoresTotais.compsIUC.iucPercentagemDescontoEletrico}` : "Sin información"}</span></p>
            </Modal>
        </>
    );
};

export default IUCComponent;
