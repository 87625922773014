import { message, Modal, Button, Tooltip, Select } from 'antd';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';

const { Option } = Select;

export default function MotivoRejeicaoDocumentacaoPerfilDeal({ data, dataUser, documentacao, setDocumentacao, confirmarDocumentos }) {


    // Função para enviar o motivo da rejeição
    const handleEnviarMotivo = () => {
        if (documentacao.descricaoDaRejeicao.trim() === "" || documentacao.tipoDocumento.length === 0) {
            message.warning('Por favor, preencha o motivo da rejeição e selecione o tipo de documento.');
        } else {

            confirmarDocumentos(documentacao.statusSelecionado, documentacao.descricaoDaRejeicao); // Envia o status e o motivo
            setDocumentacao((prevData) => ({
                ...prevData,
                isMotivoDaRejeicao: false,
            }));
        }
    };

    // Função para lidar com a melhoria do motivo com IA (Placeholder)
    const handleMotivoComAI = () => {

        if (!documentacao.descricaoDaRejeicao) {
            message.error('O Motivo de Rejeição está vazio. Escreve em poucas palavras para usar AI.');
            return
        }

        const dataMotivoComAI = {
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
            tipo_documento: documentacao.tipoDocumento,
            motivo: documentacao.descricaoDaRejeicao,
        };

        axios.post(`${process.env.REACT_APP_URL_CHATGPT}`, dataMotivoComAI)
            .then((response) => {
                if (response.data.sucesso) {

                    setDocumentacao((prevData) => ({
                        ...prevData,
                        descricaoDaRejeicao: response.data.mensagem,
                    }));

                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch((error) => {
                console.error(error);
            });

    };


    return (
        <>
            {/* Modal para o motivo da rejeição */}
            <Modal
                title="Motivo da Rejeição"
                open={documentacao.isMotivoDaRejeicao}
                onCancel={() => setDocumentacao((prevData) => ({
                    ...prevData,
                    isMotivoDaRejeicao: false,
                }))}
                footer={[
                    <div className='flex gap-2 justify-between' key="footer">
                        <div className='flex gap-2 items-center'>
                            <Button className='m-0 bg-blue-400 text-white font-bold shadow-none' key="ai" onClick={handleMotivoComAI}>
                                Melhorar com AI
                            </Button>
                            <Tooltip title="Escreva um motivo acima e clique em Melhorar com AI.">
                                <AiOutlineInfoCircle className='icon-question' />
                            </Tooltip>
                        </div>

                        <Button className='m-0 font-bold shadow-none' key="submit" type="primary" onClick={handleEnviarMotivo}>
                            Enviar
                        </Button>
                    </div>
                ]}
            >
                <div className='grid gap-2'>
                    <p className='font-semibold'>Tipo de Documentos Inválidos</p>
                    <Select
                        mode="multiple"
                        placeholder="Selecione o tipo de documento"
                        value={documentacao.tipoDocumento}
                        style={{ width: '100%', marginBottom: 16 }}
                        onChange={(value) => {

                            setDocumentacao((prevData) => ({
                                ...prevData,
                                tipoDocumento: value,
                            }));

                        }}
                    >
                        {data?.documentosData?.tipos_documentos?.[documentacao.contrato] &&
                            Object.entries(data?.documentosData.tipos_documentos[documentacao.contrato]).map(([key, value]) => (
                                <Option key={key} value={key}>
                                    {value}
                                </Option>
                            ))}
                    </Select>
                </div>
                <div className='grid gap-2'>
                    <p className='font-semibold'>Motivo da Rejeição</p>
                    <TextArea
                        className="w-full p-2 border"
                        rows={4}
                        value={documentacao.descricaoDaRejeicao}
                        onChange={(e) => setDocumentacao((prevData) => ({
                            ...prevData,
                            descricaoDaRejeicao: e.target.value,
                        }))}
                        placeholder="Descreve o motivo da rejeição..."
                    />
                </div>
            </Modal>
        </>
    );
}
