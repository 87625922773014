import React, { useState, useEffect, useRef } from "react";
import FormCalculadoraCredito from "./FormCalculadoraCredito";
import ResultadoFinanceiro from "./Resultados/ResultadoFinanceiro";
import ResultadoTotal from "./Resultados/ResultadoTotal";
import ResultadoTaxas from "./Resultados/ResultadoTaxas";

export default function CalculadoraCredito() {

    const divRef = useRef(null);  // Referência para a div que será capturada

    const [valores, setValores] = useState({
        taxaDeJuroCredito: 6.50,
        entradaCredito: 0,
        valorFinanciadoCredito: 0,
        prestacoesMesesCredito: 0,
        valorFinalCredito: 0,
        valorChaveNaMaoParaCredito: 0,
        valorChaveNaMao: 0,
        valorEntrada: 0,
        mticValor: 0,
        jurosValor: 0,
        comissaoDeCapital: 400,
        encargosdespesasValor: 2400,
        impostoDoSeloValor: 0,
        valorDoCarro: 0,
        valorDasDespesas: 0,
    });

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(valor);
    };

    useEffect(() => {
        const calcularPrestacao = () => {
            const { valorEntrada, prestacoesMesesCredito, valorChaveNaMaoParaCredito } = valores;
            if (!isNaN(valorEntrada) && !isNaN(valorChaveNaMaoParaCredito) && !isNaN(prestacoesMesesCredito)) {
                const taxaJurosMensal = valores.taxaDeJuroCredito / (12 * 100);
                const prestacao = (valorChaveNaMaoParaCredito * taxaJurosMensal) / (1 - Math.pow(1 + taxaJurosMensal, -prestacoesMesesCredito));
                setValores((prev) => ({ ...prev, valorFinalCredito: prestacao.toFixed(2) }));
            }
        };

        const calcularJuros = () => {
            const juros = (parseFloat(valores.valorFinalCredito) * parseFloat(valores.prestacoesMesesCredito)) - parseFloat(valores.valorChaveNaMaoParaCredito);
            const impostoDoSelo = juros * 0.004;
            setValores((prev) => ({
                ...prev,
                jurosValor: juros.toFixed(2),
                impostoDoSeloValor: impostoDoSelo.toFixed(2),
            }));
        };

        const calcularMtic = () => {
            const mtic = (parseFloat(valores.valorFinalCredito) * parseFloat(valores.prestacoesMesesCredito)) + parseFloat(valores.encargosdespesasValor);
            setValores((prev) => ({ ...prev, mticValor: mtic.toFixed(2) }));
        };

        calcularPrestacao();
        calcularMtic();
        calcularJuros();
    }, [valores.prestacoesMesesCredito, valores.encargosdespesasValor, valores.valorFinalCredito]);

    useEffect(() => {
        if (valores.valorChaveNaMao > 0) {
            const novoValorEntrada = 0.2 * valores.valorChaveNaMao;
            setValores((prev) => ({
                ...prev,
                valorEntrada: novoValorEntrada,
                valorChaveNaMaoParaCredito: valores.valorChaveNaMao - novoValorEntrada,
            }));
        }
    }, [valores.valorChaveNaMao]);

    useEffect(() => {
        const valorCalculado = parseFloat(valores.valorEntrada) + parseFloat(valores.mticValor) + parseFloat(valores.comissaoDeCapital);
        setValores((prev) => ({
            ...prev,
            valorDoCarro: valorCalculado,
        }));
    }, [valores.valorEntrada, valores.mticValor, valores.comissaoDeCapital]);

    useEffect(() => {
        const valorCalculado = parseFloat(valores.encargosdespesasValor) + parseFloat(valores.comissaoDeCapital);
        setValores((prev) => ({
            ...prev,
            valorDasDespesas: valorCalculado,
        }));
    }, [valores.encargosdespesasValor, valores.comissaoDeCapital]);

    return (
        <div className='grid gap-4'>
            <div className={valores.valorChaveNaMaoParaCredito && valores.prestacoesMesesCredito ? 'grid grid-cols-1 md:grid-cols-3 md:gap-4' : 'grid grid-cols-1 md:grid-cols-1 md:gap-4'}>

                <div className="col-span-2">
                    <FormCalculadoraCredito
                        valores={valores}
                        setValores={setValores}
                        formatarNumero={formatarNumero}
                    />
                </div>

                <div
                    className={`transition-all duration-500 ease-in-out ${valores.valorChaveNaMaoParaCredito && valores.prestacoesMesesCredito
                        ? 'opacity-100 card'
                        : 'opacity-0 max-h-0 overflow-hidden'
                        }`}
                    style={{ transitionProperty: 'opacity, max-height' }}
                >
                    <div ref={divRef} className="bg-tipos grid grid-cols-1 gap-6 mt-5 md:mt-0">

                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 text-white">
                            <h2 className="font-bold text-base">Total da simulação</h2>
                            <div className="grid grid-cols-2 gap-4 items-center">
                                <div className="flex rounded bg-[#3C3C40] p-4 items-center">
                                    <p><span className="text-base sm:text-base font-bold">{valores.prestacoesMesesCredito}</span> meses X</p>
                                </div>
                                <p><span className="text-lg font-bold">{formatarNumero(valores.valorFinalCredito)}</span> €/mês</p>
                            </div>
                        </div>

                        <hr />

                        <ResultadoTaxas
                            taxaDeJuroCredito={valores.taxaDeJuroCredito}
                            tipoDeTaxa="Fixa"
                        />

                        <hr />

                        <ResultadoFinanceiro
                            mticValor={formatarNumero(valores.mticValor)}
                            valorChaveNaMaoParaCredito={formatarNumero(valores.valorChaveNaMaoParaCredito)}
                            jurosValor={formatarNumero(valores.jurosValor)}
                            valorDasDespesas={formatarNumero(valores.valorDasDespesas)}
                        />

                        <hr />

                        <ResultadoTotal
                            titulo="Valor Total do Financiamento"
                            valor={formatarNumero(valores.mticValor)}
                            bgColor="bg-[#3C3C40]"
                            textColor="text-[#3EA163]" // Define a cor personalizada do texto
                        />

                        <ResultadoTotal
                            titulo="Valor Total da Viatura"
                            valor={formatarNumero(valores.valorDoCarro)}
                            bgColor="bg-[#3C3C40]"
                            textColor="text-[#069CFF]" // Define a cor personalizada do texto
                        />

                    </div>

                </div>
            </div>
        </div >
    );
}
