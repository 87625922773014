import React, { useEffect, useState } from "react";
import TitleSection from "../../components/Elements/Title";
import CustomTable from "../../components/Tables/CustomTables";
import axios from "axios";
import flagPortugal from '../../assets/flags/flag-portugal.svg';
import flagSpain from '../../assets/flags/flag-spain.svg';
import { LuFilter } from "react-icons/lu";
import { Button, Drawer, Select } from "antd";

const { Option } = Select;

export default function LogsDevs({
    dataUser
}) {

    const [drawerVisibleFilters, setDrawerVisibleFilters] = useState(false); // Controla a visibilidade do Filtros

    const [data, setData] = useState({
        logs: [],
        filters: ['formulario_contacto'],
        filterPais: 'all'
    });

    const [loading, setLoading] = useState(true); // Controla a visibilidade do Filtros


    useEffect(() => {

        if (!data.filters || data.filters.length === 0) return; // Added check for undefined

        setLoading(true)
        const allFilters = data.filters.join(',');
        
        const fetchData = async () => {
            let url = `${process.env.REACT_APP_LOGS_AUTOMACOES}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&filter=${allFilters}&pais=${data.filterPais}`;

            try {
                const response = await axios.get(url);

                setData({
                    logs: response.data.mensagem,
                });

                setLoading(false)

            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [dataUser.iDTokenAuthenticated, data.filters, data.filterPais]);


    const filterOptions = [
        'formulario_contacto',
        'simulador_propostas',
        'gerador_documentos',
        'simulador_isv_pt',
        'proposta_reaberta',
        'proposta_fechada',
        'whatsapp_feedback_formulario_contacto',
        'simulador_iframe'
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'País',
            dataIndex: 'pais',
            key: 'pais',
            render: (pais) => (
                <img
                    src={pais === "pt" || pais === "Portugal" ? flagPortugal : flagSpain}
                    alt={pais === "pt" || pais === "Portugal" ? "Portugal" : "Spain"}
                    style={{ marginRight: '8px', width: '35px', height: '22px' }}
                />
            )
        },
        {
            title: 'Tabela',
            dataIndex: 'tabela',
            key: 'tabela',
        },
        {
            title: 'Data',
            dataIndex: 'data_criacao',
            key: 'data_criacao',
        },
        {
            title: 'Assunto da Mensagem',
            dataIndex: 'mensagem',
            key: 'mensagem',
            render: (html) => (
                <div dangerouslySetInnerHTML={{ __html: html.slice(0, 50) + (html.length > 50 ? '...' : '') }} />
            )
        }
    ];


    const showDrawerFilters = () => {
        setDrawerVisibleFilters(true);
    };

    const closeDrawerFilters = () => {
        setDrawerVisibleFilters(false);
    };


    const handleTipoLogClick = (value) => {
        setData((prev) => ({
            ...prev,
            filters: value
        }));
    };

    const handlePaisClick = (value) => {
        setData((prev) => ({
            ...prev,
            filterPais: value
        }));
    };


    return (

        <div className='card grid gap-4'>
            <TitleSection
                title='Logs Devs'
                description=''
            />

            <div className='flex justify-end'>
                <Button
                    type="primary"
                    className='shadow-none font-bold text-black bg-white'
                    onClick={showDrawerFilters}
                >
                    <div className='flex items-center gap-2'>
                        <LuFilter />
                        Filtros
                    </div>
                </Button>
            </div>

            <CustomTable
                columns={columns}
                data={data.logs.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={{ pageSize: 20, showSizeChanger: false, position: ['bottomCenter'] }}
                loading={loading}
            />

            <Drawer
                title="Filtros"
                placement="right"
                className='detalhes-de-log-email'
                onClose={closeDrawerFilters}
                open={drawerVisibleFilters}
            >
                <div className='flex flex-col mb-6'>
                    <p className='mb-2 font-semibold'>Tipo</p>
                    <Select
                        mode="multiple"
                        value={data.filters || undefined}
                        onChange={(value) => handleTipoLogClick(value)}
                        placeholder="Tipo"
                    >
                        {filterOptions.map(option => (
                            <Option key={option} value={option}>
                                {option}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='flex flex-col mb-6'>
                    <p className='mb-2 font-semibold'>País</p>
                    <Select
                        placeholder={'País'}
                        className='select-filters capitalize'
                        value={data.filterPais || undefined}
                        onChange={(value) => handlePaisClick(value)}
                    >
                        <Select.Option key="TODOS" value="all" className="capitalize">Todos</Select.Option>
                        <Select.Option key="PT" value="pt" className="capitalize">Portugal</Select.Option>
                        <Select.Option key="ES" value="es" className="capitalize">Espanha</Select.Option>
                    </Select>
                </div>

            </Drawer >

        </div >

    )
}
