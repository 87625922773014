import React, { useState } from 'react';
import { Input, Tooltip, Switch } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import ExpressInfoModal from './ExpressInfoDrawer';

export default function TaxaDeExpressProposta({ valoresTotais, setValoresTotais }) {


    // const handleExpress = (checked) => {
    //     setValoresTotais((prevData) => ({
    //         ...prevData,
    //         valorExpressAtivo: checked ? 1 : 0
    //     }));
    // }


    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Express</p>
                </div>
            </div>
            <hr />
            {/* <div>
                <label className='flex gap-2 items-center cursor-pointer'>
                    <Switch checked={valoresTotais.valorExpressAtivo === 1} onChange={handleExpress} />
                    <span>Esta proposta vai ter transporte Express?</span>
                    <Tooltip title="A taxa de financiamento serve para cobrir a disponibilização de capital por parte da Importrust, que adiantará 80% do valor do carro até que este legalizado (altura em que a financeira do cliente disponibilizará o valor financiado).">
                        <AiOutlineInfoCircle className='icon-question' />
                    </Tooltip>
                </label>
            </div> */}

            {/* {valoresTotais.valorExpressAtivo == 1 && ( */}
            <div className='grid gap-3'>
                <div className='flex items-center gap-2'>
                    <p>Valor de transporte express</p>
                    <ExpressInfoModal />
                </div>
                <Input
                    placeholder="Valor de transporte express"
                    value={valoresTotais.valorExpress}
                    onChange={(e) => {
                        const value = e.target.value;

                        // Atualiza o estado com o valor digitado, permitindo valores temporários menores que 1000
                        setValoresTotais((prevData) => ({
                            ...prevData,
                            valorExpress: value
                        }));
                    }}
                    onBlur={(e) => {
                        const value = parseFloat(e.target.value);

                        // Ao sair do campo, valida o valor para garantir que seja pelo menos 1000
                        setValoresTotais((prevData) => ({
                            ...prevData,
                            valorExpress: isNaN(value) || value < 1000 ? 1000 : value
                        }));
                    }}
                    type="number"
                    min="1000"
                />

            </div>
            {/* )} */}
        </div>
    );
};

