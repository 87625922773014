import { AutoComplete, Spin, message } from 'antd';
import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../../../assets/hubspot.svg';
import TableDealsHubpotProposta from '../TableDealsHubpotProposta';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 25,
        }}
        spin
    />
);


export default function AssociarDeal({ dataUser, dataCarros, setIsModalOpen, setHubspotInfo, setPropostaInfo, propostaInfo, tituloCTA, descricao }) {

    const { t } = useTranslation();

    const [pesquisaEmailDeal, setPesquisaEmailDeal] = useState('');
    const [data, setData] = useState([]);
    const [listaDeDeals, setListaDeDeals] = useState([]);
    const [loading, setLoading] = useState(false); // loading na pesquisa do deal
    const [timeoutId, setTimeoutId] = useState(null);

    // Função para realizar a pesquisa pelo email ou contacto do cliente
    const handlePesquisaDealChange = (value) => {
        setPesquisaEmailDeal(value);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (value.length > 3) {
            setLoading(true); // Inicia o estado de carregamento
            const newTimeoutId = setTimeout(() => {
                let url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?email=${value}&token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&live`;

                axios
                    .get(url)
                    .then((response) => {
                        const email = response.data.contactos.map((item) => ({
                            value: item.properties.email,
                            label: `${item.properties.firstname} ${item.properties.lastname || ''} | (${item.properties.email}) | (${item.properties.hs_calculated_phone_number || item.properties.mobilephone || item.properties.phone || ''})`,
                            deals: item?.deals,
                        }));
                        if (email.length > 0) {
                            setData(email);
                        } else {
                            message.warning(t('gerar_proposta.sem_resultados'));
                        }
                    })
                    .catch((error) => {
                        console.error('Error occurred:', error);
                    })
                    .finally(() => {
                        setLoading(false); // Finaliza o estado de carregamento
                    });
            }, 1000);

            setTimeoutId(newTimeoutId);
        } else {
            setData([]);
        }
    };


    // Função para lidar com a seleção de um email
    const handlePesquisaDealSelect = (value) => {
        setLoading(false);
        setPesquisaEmailDeal(value);

        const selectedOption = data.find(option => option.value === value);
        // console.log(selectedOption.deals.length > 0)
        if (selectedOption.deals.length > 0) {
            setListaDeDeals(selectedOption.deals || []);
        } else {
            message.warning(t('gerar_proposta.listadealsvazia'))
        }
    }


    return (
        <>
            <div className='mt-7 mb-7 grid gap-4'>
                <div className='card'>
                    <div className='flex items-center gap-4 text-white'>
                        <img
                            src={logo}
                            alt="Logo"
                            className="w-24 h-10 object-contain"
                        />
                        <span>|</span>
                        <span className='text-base font-semibold'>{tituloCTA}</span>
                    </div>
                </div>

                <div className="grid gap-4 content-start popup-associar">

                    <p className='text-lg font-bold'>{t('gerar_proposta.pesquisa_email_contacto')}</p>

                    <div className="flex items-center gap-2">
                        <AutoComplete
                            value={pesquisaEmailDeal}
                            options={data}
                            onChange={handlePesquisaDealChange}
                            onSelect={handlePesquisaDealSelect}
                            placeholder={t('gerar_proposta.pesquisa_email_contacto_input')}
                            style={{ width: '100%' }}
                        />
                        {loading && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spin indicator={antIcon} />
                            </div>
                        )}
                    </div>

                    {listaDeDeals.length > 0 && (
                        <TableDealsHubpotProposta
                            listaDeDeals={listaDeDeals}
                            dataUser={dataUser}
                            dataCarros={dataCarros}
                            setIsModalOpen={setIsModalOpen}
                            setHubspotInfo={setHubspotInfo}
                            propostaInfo={propostaInfo}
                            setPropostaInfo={setPropostaInfo}
                            tituloCTA={tituloCTA}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
