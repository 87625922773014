import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/Elements/Title';
import CustomTable from '../../components/Tables/CustomTables';
import { Tooltip, Drawer } from 'antd';

export default function Notificacoes({ dataUser, notificacaoState }) {

    const { t } = useTranslation();

    const [data, setData] = useState({
        notificacoes: [],
        dataNotificacao: null,
        countNotificacoes: 0,
    });

    const [loading, setLoading] = useState(true); // Estado de loading

    const [drawerVisibleEmailDetail, setDrawerVisibleEmailDetail] = useState(false); // Controla a visibilidade do Detalhes do Email

    // Fetch das notificações quando o componente é montado
    useEffect(() => {
        setLoading(true);
        const url = `${process.env.REACT_APP_URL_NOTIFICACOES}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&aberta=1`;

        axios.get(url)
            .then((response) => {
                setData({
                    notificacoes: response.data.data.notificacoes,
                    countNotificacoes: response.data.data.contagem,
                });
                setLoading(false);
            })
            .catch((error) => {
                console.error('Erro ao buscar notificações:', error);
            });
    }, [dataUser]);

    // Função para abrir o Drawer com os detalhes da notificação
    const showDrawerVisibleEmailDetail = (record) => {
        setDrawerVisibleEmailDetail(true);
        setData((prevData) => ({
            ...prevData,
            dataNotificacao: record, // Salva a notificação clicada
        }));
    };

    // Função para fechar o Drawer
    const closeDrawerVisibleEmailDetail = () => {
        setDrawerVisibleEmailDetail(false);
    };

    // Colunas da tabela
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id_notificacao',
            key: 'id_notificacao',
        },
        {
            title: 'Data',
            dataIndex: 'data_criacao',
            key: 'data_criacao',
        },
        {
            title: 'Assunto',
            dataIndex: 'assunto',
            key: 'assunto',
            render: (text, record) => {
                const displayedName = `${text.slice(0, 50)}...`;

                return (
                    <Tooltip placement="topRight" title={text}>
                        <span
                            style={{ cursor: 'pointer', color: '#fff' }} // Estilo clicável com cor de link
                            onClick={() => showDrawerVisibleEmailDetail(record)}
                        >
                            {displayedName}
                        </span>
                    </Tooltip>
                );
            }
        },
    ];

    
    return (
        <>
            <div className='card grid gap-4'>
                <TitleSection
                    title={t('notificacoes.titulo_principal')}
                    description={''}
                />
                <CustomTable
                    columns={columns}
                    data={data.notificacoes.map((record, index) => ({ ...record, key: record.id || index }))}
                    pagination={{ pageSize: 20, showSizeChanger: false, position: ['bottomCenter'] }}
                    loading={loading}
                />

                {/* Drawer para os detalhes da notificação */}
                <Drawer
                    title="Detalhes da Notificação"
                    placement="right"
                    className='detalhes-de-log-email'
                    onClose={closeDrawerVisibleEmailDetail}
                    open={drawerVisibleEmailDetail}
                >
                    {/* Conteúdo do Drawer - você pode adicionar mais detalhes da notificação aqui */}
                    {data.dataNotificacao && (
                        <div className="grid gap-4">
                            <div className="grid gap-2">
                                <p><strong>ID da Notificação:</strong> {data.dataNotificacao.id_notificacao}</p>
                                <p><strong>Data da Criação:</strong> {data.dataNotificacao.data_criacao}</p>
                                <p><strong>Data de Abertura:</strong> {data.dataNotificacao.data_aberta}</p>
                                <p><strong>Assunto:</strong> {data.dataNotificacao.assunto}</p>
                            </div>

                            <hr />

                            <p><strong>Mensagem:</strong></p>
                            <div
                                dangerouslySetInnerHTML={{ __html: data.dataNotificacao.mensagem }}
                                style={{
                                    borderRadius: '10px',
                                    height: '100%',
                                    overflow: 'hidden',
                                    position: 'relative',
                                }}
                            />
                        </div>
                    )}
                </Drawer>
            </div>
        </>
    );
}
