import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineExpandMore, MdOutlineKeyboardArrowUp } from 'react-icons/md';


export default function DadosTecnicos({ dataCarros }) {

    const { t } = useTranslation();


    const [moreDadosTecnicos, setDadosTecnicos] = useState(false);

    // Função para alternar o estado
    const toggleDadosTecnicos = () => {
        setDadosTecnicos((prevState) => !prevState);
    };


    return (
        <div className="card grid gap-4">
            <div onClick={toggleDadosTecnicos} className="flex justify-between items-center gap-4 cursor-pointer">
                <div className="grid gap-1">
                    <p className="text-lg font-semibold">{t('tables.dados_tecnicos')}</p>
                </div>

                {moreDadosTecnicos ? (
                    <MdOutlineKeyboardArrowUp size={25} />
                ) : (
                    <MdOutlineExpandMore size={25} />
                )}

            </div>
            {moreDadosTecnicos && (
                <>
                    <hr />
                    <div className="grid grid-cols-1 gap-x-10 gap-y-2">
                        {Object.entries(dataCarros.dados_tecnicos).map(([key, value], index) => (
                            <div
                                key={index}
                                className="grid grid-cols-2 gap-4 w-full text-sm"
                            >
                                <span className='font-semibold'>{key}:</span>
                                <p className="font-normal">
                                    {value}
                                </p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
