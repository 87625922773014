import React, { useState } from 'react';
import { Button, Drawer, Input, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export default function DrawerFiltrosDaAutomacao({ filters, setFilters, drawerVisibleFilters, setDrawerVisibleFilters, dataUser }) {

    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState([null, null]);


    // Função para fechar o Drawer dos Filtros
    const closeDrawerFilters = () => {
        setDrawerVisibleFilters(false);
    };

    const handleEventoClick = (value) => {
        setFilters((prev) => ({
            ...prev,
            nomeEvento: value
        }));
    };

    const handleTipoDeEmailClick = (value) => {
        setFilters((prev) => ({
            ...prev,
            template: value
        }));
    };

    const handleQuantidadePedidos = (value) => {
        setFilters((prev) => ({
            ...prev,
            limitPedidos: value
        }));
    };

    const handleQuantidadePorPagina = (value) => {
        setFilters((prev) => ({
            ...prev,
            porPagina: value
        }));
    };

    const handleDeInput = (event) => {
        const { value } = event.target;
        setFilters((prev) => ({
            ...prev,
            de: value
        }));
    };

    const handleParaInput = (event) => {
        const { value } = event.target;
        setFilters((prev) => ({
            ...prev,
            para: value
        }));
    };

    const handleDateChange = (dates, dateStrings) => {
        setDateRange(dates);
        setFilters((prev) => ({
            ...prev,
            dataCriacaoInicio: dateStrings[0] || null,
            dataCriacaoFim: dateStrings[1] || null,
        }));
    };

    // Função para resetar os filtros
    const resetFilters = () => {
        setDateRange([null, null]);
        setFilters({
            nomeEvento: [],
            template: [],
            de: '',
            para: '',
            dataCriacaoInicio: [],
            dataCriacaoFim: [],
        });
    };


    // Verifica se há algum filtro selecionado
    const hasFilters = filters.nomeEvento.length > 0 || filters.template.length > 0 || filters.de || filters.para || filters.dataCriacaoInicio || filters.dataCriacaoFim;


    return (
        <Drawer
            title="Filtros"
            placement="right"
            className='detalhes-de-log-email'
            onClose={closeDrawerFilters}
            open={drawerVisibleFilters}
        >


            {hasFilters && (
                <div>
                    <Button
                        type="primary"
                        className='shadow-none font-bold text-black bg-white mb-6 w-full'
                        onClick={resetFilters}
                    >
                        {t('tables.limpar_filtros')}
                    </Button>
                </div>
            )}

            <div className='flex flex-col mb-6'>
                <p className='mb-2 font-semibold'>{t('tables.evento_automacao')}</p>
                <Select
                    mode="multiple"
                    placeholder={t('tables.evento_automacao')}
                    className='select-filters capitalize'
                    onChange={(value) => handleEventoClick(value)}
                    value={filters.nomeEvento || undefined}
                    style={{ width: '100%' }}
                >
                    <Select.Option key="Enviado" value="Enviado" className="capitalize">{t('tables.enviado')}</Select.Option>
                    <Select.Option key="Entregue" value="Entregue" className="capitalize">{t('tables.entregue')}</Select.Option>
                    <Select.Option key="Falha" value="Falha" className="capitalize">{t('tables.falha')}</Select.Option>
                    <Select.Option key="Aberto" value="Aberto" className="capitalize">{t('tables.aberto_automacao')}</Select.Option>
                    <Select.Option key="Clique" value="Clique" className="capitalize">{t('tables.clique')}</Select.Option>
                    <Select.Option key="Unsubscribe" value="Unsubscribe" className="capitalize">{t('tables.unsubscribe')}</Select.Option>
                </Select>
            </div>

            <div className='flex flex-col mb-6'>
                <p className='mb-2 font-semibold'>{t('tables.tipo_email')}</p>
                <Select
                    mode="multiple"
                    placeholder={t('tables.tipo_email')}
                    className="select-filters capitalize"
                    onChange={(value) => handleTipoDeEmailClick(value)}
                    value={filters.template || undefined}
                    style={{ width: '100%' }}
                >
                    {dataUser.role == "administrator" ? (
                        <>
                            {/* Opções em Português */}
                            <Select.Option key="isv_iuc" value="isv_iuc" className="capitalize">
                                {t('tables.isv_iuc')}
                            </Select.Option>
                            <Select.Option key="formulario_contacto_pt" value="formulario_contacto_pt" className="capitalize">
                                {t('tables.formulario_contacto_pt')}
                            </Select.Option>
                            <Select.Option key="automacoes_stands_transportadoras" value="automacoes_stands_transportadoras" className="capitalize">
                                {t('tables.automacoes_stands_transportadoras')}
                            </Select.Option>
                            <Select.Option key="automacoes_pt" value="automacoes_pt" className="capitalize">
                                {t('tables.automacoes_pt')}
                            </Select.Option>
                            <Select.Option key="propostas_aceites" value="propostas_aceites" className="capitalize">
                                {t('tables.propostas_aceites')}
                            </Select.Option>

                            {/* Opções em Espanhol */}
                            <Select.Option key="formulario_contacto_es" value="formulario_contacto_es" className="capitalize">
                                {t('tables.formulario_contacto_es')}
                            </Select.Option>
                            <Select.Option key="automacoes_es" value="automacoes_es" className="capitalize">
                                {t('tables.automacoes_es')}
                            </Select.Option>
                        </>
                    ) : (
                        <>
                            {dataUser.language === 'pt' && (
                                <>
                                    <Select.Option key="isv_iuc" value="isv_iuc" className="capitalize">
                                        {t('tables.isv_iuc')}
                                    </Select.Option>
                                    <Select.Option key="formulario_contacto_pt" value="formulario_contacto_pt" className="capitalize">
                                        {t('tables.formulario_contacto_pt')}
                                    </Select.Option>
                                    <Select.Option key="automacoes_stands_transportadoras" value="automacoes_stands_transportadoras" className="capitalize">
                                        {t('tables.automacoes_stands_transportadoras')}
                                    </Select.Option>
                                    <Select.Option key="automacoes_pt" value="automacoes_pt" className="capitalize">
                                        {t('tables.automacoes_pt')}
                                    </Select.Option>
                                    <Select.Option key="propostas_aceites" value="propostas_aceites" className="capitalize">
                                        {t('tables.propostas_aceites')}
                                    </Select.Option>
                                </>
                            )}
                            {dataUser.language === 'es' && (
                                <>
                                    <Select.Option key="formulario_contacto_es" value="formulario_contacto_es" className="capitalize">
                                        {t('tables.formulario_contacto_es')}
                                    </Select.Option>
                                    <Select.Option key="automacoes_es" value="automacoes_es" className="capitalize">
                                        {t('tables.automacoes_es')}
                                    </Select.Option>
                                </>
                            )}
                        </>
                    )}
                </Select>

            </div>

            <div className='flex flex-col mb-6'>
                <p className='mb-2 font-semibold'>{t('tables.de')}</p>
                <Input
                    type="text"
                    placeholder={t('tables.de')}
                    value={filters.de || undefined}
                    onChange={(value) => handleDeInput(value)}
                    prefix={<SearchOutlined />}
                />
            </div>

            <div className='flex flex-col mb-6'>
                <p className='mb-2 font-semibold'>{t('tables.para')}</p>
                <Input
                    type="text"
                    placeholder={t('tables.para')}
                    value={filters.para || undefined}
                    onChange={(value) => handleParaInput(value)}
                    prefix={<SearchOutlined />}
                />
            </div>

            <div className='flex flex-col mb-6'>
                <p className='mb-2 font-semibold'>{t('tables.data_automacao')}</p>
                <RangePicker
                    showTime={{ format: 'HH:mm' }} // Adicionado: mostrar seleção de horas e minutos
                    format="YYYY-MM-DD HH:mm" // Definido o formato de exibição incluindo data e hora
                    value={dateRange}
                    onChange={handleDateChange}
                    placeholder={['Data de início', 'Data de fim']}
                />
            </div>



            <div className='flex flex-col mb-6'>
                <p className='mb-2 font-semibold'>{t('tables.qtd_por_pagina')}</p>
                <Select
                    placeholder={t('tables.qtd_por_pagina')}
                    className="select-filters capitalize"
                    onChange={(value) => handleQuantidadePorPagina(value)}
                    value={filters.porPagina || undefined}
                >
                    <Select.Option key="20" value="20" className="capitalize">
                        20
                    </Select.Option>
                    <Select.Option key="50" value="50" className="capitalize">
                        50
                    </Select.Option>
                    <Select.Option key="100" value="100" className="capitalize">
                        100
                    </Select.Option>
                </Select>
            </div>

            <div className='flex flex-col mb-6'>
                <p className='mb-2 font-semibold'>{t('tables.total_de_pedidos')}</p>
                <Select
                    placeholder={t('tables.total_de_pedidos')}
                    className="select-filters capitalize"
                    onChange={(value) => handleQuantidadePedidos(value)}
                    value={filters.limitPedidos || undefined}
                >
                    <Select.Option key="100" value="100" className="capitalize">
                        100
                    </Select.Option>
                    <Select.Option key="200" value="200" className="capitalize">
                        200
                    </Select.Option>
                    <Select.Option key="300" value="300" className="capitalize">
                        300
                    </Select.Option>
                </Select>
            </div>


        </Drawer>
    );
};

