import React, { useState } from 'react';
import { Drawer, Radio, Timeline, message } from 'antd';
import axios from 'axios';
import { FaCheckCircle, FaClock } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import 'swiper/css';
import 'swiper/css/navigation';
import VerDocumentacaoPerfilDeal from './DocumentacaoPerfilDeal/VerDocumentacaoPerfilDeal';
import MotivoRejeicaoDocumentacaoPerfilDeal from './DocumentacaoPerfilDeal/MotivoRejeicaoDocumentacaoPerfilDeal';
import Loading from '../../Elements/Loading';
import { useTranslation } from 'react-i18next';


export default function DocumentacaoPerfilDeal({ setDrawerHistoricoRejeicao, drawerHistoricoRejeicao, data, setData, dataUser }) {

    const { t } = useTranslation();

    const [documentacao, setDocumentacao] = useState({
        isMotivoDaRejeicao: false,
        descricaoDaRejeicao: '',
        statusSelecionado: '',
        tipoDocumento: [],
        contrato: data.documentosData?.info?.tipo_contrato,
        historicoRejeicao: data.documentosData?.historico_rejeicoes,
    });


    // Função para lidar com a mudança no status dos documentos
    const handleConfirmarDocs = ({ target: { value } }) => {

        setDocumentacao((prevData) => ({
            ...prevData,
            tipoDocumento: [],
            statusSelecionado: value,
        }));

        if (value === 'rejected') {
            setDocumentacao((prevData) => ({
                ...prevData,
                isMotivoDaRejeicao: true,
                descricaoDaRejeicao: '',
            }));
        } else {
            confirmarDocumentos(value);
        }

    };


    // Função para confirmar documentos
    const confirmarDocumentos = (status, motivo = '') => {

        const dataConfirmarDocs = {
            id_deal: data?.dealsData?.id, // Use dados corretamente do props
            status: status,
            user_id: dataUser.iDTokenAuthenticated, // Certifique-se que user_id vem de dataUser ou do contexto
            token: dataUser.saveToken, // Pegue o token corretamente
            tipo_documento: documentacao.tipoDocumento,
            motivo: motivo,
        };

        axios.post(`${process.env.REACT_APP_CONFIRMAR_DOCS}`, dataConfirmarDocs)
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);

                    setData((prevData) => ({
                        ...prevData,
                        documentosData: {
                            ...prevData.documentosData,
                            info: {
                                ...prevData.documentosData.info,
                                status: response.data.status, // Atualiza o status no info
                            },
                            estado_documentos: response.data.status_dos_documentos
                        },
                    }));
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch((error) => {
                console.error(error);
            });

    };

    const closeDrawerHistoricoRejeicao = () => {
        setDrawerHistoricoRejeicao(false);
    };


    // console.log(documentacao.historicoRejeicao)

    return (
        <>
            <div className='flex items-center justify-end gap-1'>
                <div className='flex gap-4 items-center'>
                    {/* Grupo de botões de rádio para status de documentação */}
                    <Radio.Group
                        onChange={handleConfirmarDocs}
                        value={data?.documentosData?.info?.status || 'validation'} // Evita erro se data.documentosData estiver indefinido
                        optionType="button"
                        buttonStyle="solid"
                        className='flex'
                    >
                        {/* Botão de status "completed" */}
                        <Radio.Button
                            value="completed"
                            style={{
                                backgroundColor: data?.documentosData?.info?.status === 'completed' ? '#0D9F57' : '#FFFFFF', // Verde se ativo
                                color: data?.documentosData?.info?.status === 'completed' ? '#FFFFFF' : '#000000' // Branco se ativo
                            }}
                            className='flex items-center'
                        >
                            <FaCheckCircle style={{ fontSize: '17px' }} />
                        </Radio.Button>

                        {/* Botão de status "validation" */}
                        <Radio.Button
                            value="validation"
                            style={{
                                backgroundColor: data?.documentosData?.info?.status === 'validation' ? '#eab308' : '#FFFFFF', // Amarelo se ativo
                                color: data?.documentosData?.info?.status === 'validation' ? '#FFFFFF' : '#000000' // Branco se ativo
                            }}
                            className='flex items-center'
                        >
                            <FaClock style={{ fontSize: '17px' }} />
                        </Radio.Button>

                        {/* Botão de status "rejected" */}
                        <Radio.Button
                            value="rejected"
                            style={{
                                backgroundColor: data?.documentosData?.info?.status === 'rejected' ? '#F44336' : '#FFFFFF', // Vermelho se ativo
                                color: data?.documentosData?.info?.status === 'rejected' ? '#FFFFFF' : '#000000' // Branco se ativo
                            }}
                            className='flex items-center'
                        >
                            <IoMdCloseCircle style={{ fontSize: '20px' }} />
                        </Radio.Button>
                    </Radio.Group>
                </div>
            </div>


            <VerDocumentacaoPerfilDeal
                data={data}
                setData={setData}
                dataUser={dataUser}
            />

            <MotivoRejeicaoDocumentacaoPerfilDeal
                data={data}
                dataUser={dataUser}
                documentacao={documentacao}
                setDocumentacao={setDocumentacao}
                confirmarDocumentos={confirmarDocumentos}
            />

            <Drawer
                title={t('hubspot.historico_documentacao')}
                placement="right"
                className='detalhes-de-log-email'
                onClose={closeDrawerHistoricoRejeicao}
                open={drawerHistoricoRejeicao}
            >
                <div className='flex flex-col mb-6'>
                    <Timeline>
                        {documentacao.historicoRejeicao.map((item, index) => (
                            <Timeline.Item key={index}>
                                <p><strong>Data de Rejeição:</strong> {item.data_rejeicao}</p>
                                <p><strong>Documentos Rejeitados:</strong> {item.documentos_rejeitados.join(", ")}</p>
                                <p><strong>Motivo:</strong> {item.motivo_rejeitado}</p>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </div>
            </Drawer>
        </>
    );
}
