import React from 'react';

export default function ResultadoFinanceiro({ mticValor, valorChaveNaMaoParaCredito, jurosValor, valorDasDespesas }) {
    return (

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="grid gap-2 grid-cols-1 md:grid-cols-1 bg-[#3C3C40] rounded text-white p-2">
                <p className="flex items-center gap-2">
                    <span className="text-sm">MTIC</span>
                </p>
                <p><span className="text-base sm:text-base font-bold">{mticValor ? `${mticValor}` : `0 €`}</span></p>
            </div>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-1 bg-[#3C3C40] rounded text-white p-2">
                <p className="flex items-center gap-2">
                    <span className="text-sm">Montante Financiado</span>
                </p>
                <p><span className="text-base sm:text-base font-bold">{valorChaveNaMaoParaCredito ? `${valorChaveNaMaoParaCredito}` : `0 €`}</span></p>
            </div>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-1 bg-[#3C3C40] rounded text-white p-2">
                <p className="flex items-center gap-2">
                    <span className="text-sm">Juros</span>
                </p>
                <p className="flex items-center gap-2"><span className="text-base sm:text-base font-bold">{jurosValor ? `${jurosValor}` : `0 €`}</span></p>
            </div>
            <div className="grid gap-2 grid-cols-1 md:grid-cols-1 bg-[#3C3C40] rounded text-white p-2">
                <p className="flex items-center gap-2">
                    <span className="text-sm">Encargos e Despesas</span>
                </p>
                <p className="flex items-center gap-2"><span className="text-base sm:text-base font-bold">{valorDasDespesas ? `${valorDasDespesas}` : `0 €`}</span></p>
            </div>
        </div>
    );
}
