import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NoResults() {
    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '16px', color: '#888' }}>
            {t('error.frase')}
        </div>
    );
}
