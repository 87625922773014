import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Select, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiRefresh } from 'react-icons/hi';

const { Option } = Select;

export default function RegistarPropostaFormES ({ isvIucData, valoresTotais, setUrlCarroLink, dataUser, linkToken }) {

    const { t } = useTranslation();

    const navigate = useNavigate(); // Hook para navegação


    const [form] = Form.useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); //bloqueia o button de criar proposta
    const [propostaNome, setPropostaNome] = useState(''); //nome da proposta
    const [tipoProposta, setTipoProposta] = useState('proposta_cliente'); // tipo da Proposta


    useEffect(() => {
        // Verifica se todos os campos do FormCalculoProposta estão preenchidos
        const isFormCalculoValid = Object.entries(isvIucData)
            .filter(([key]) => {
                // Ignora os campos especificados
                if (key === 'tipoDeVeiculoParaInsencoes' || key === 'outrosServicos') {
                    return false;
                }
    
                // Se o tipo de combustível for "Eletrico", ignora os campos 'cilindrada' e 'emissoesCO2'
                if (isvIucData.tipoCombustivel === 'Eletrico' && (key === 'cilindrada' || key === 'emissoesCO2' || key === "particulas")) {
                    return false;
                }
    
                return true;
            })
            .every(([, value]) => value !== '' && value !== null && value !== undefined);
        
            // console.log(isvIucData)
            // console.log(isFormCalculoValid)

        // Habilita o botão apenas se o nome da proposta e todos os campos do FormCalculoProposta estiverem preenchidos
        setIsButtonDisabled(!(propostaNome && isFormCalculoValid));
    }, [propostaNome, isvIucData]);
    


    const handleSubmit = () => {

        let listaDeDadosAtualizados = {

            cilindrada: isvIucData.cilindrada,
            novo: isvIucData.novoUsado == "Usado" ? 0 : 1,
            tipo_de_combustivel: isvIucData.tipoCombustivel,
            emissoes_numerico: isvIucData.emissoesCO2,
            data_registo: isvIucData.dataPrimeiroRegisto,
            particulas: isvIucData.particulas,
            preco_final_carro: valoresTotais.precoViaturaComIva,
            preco_final_carro_sem_iva: valoresTotais.precoViaturaSemIva,
            tipo_de_veiculo: isvIucData.tipoDeVeiculoParaOutros,
            tipo_de_isencoes: isvIucData.tipoDeVeiculoParaInsencoes,
            hibrido: isvIucData.tipoCombustivel === "Eletrico" ? 0 : (isvIucData.hibridoPlugIn ? 1 : 0),


            id_modelo: isvIucData.idModelo,
            nome_modelo: isvIucData.nomeModelo,
            id_marca: isvIucData.idMarca,
            nome_marca: isvIucData.nomeMarca,
            valor_viatura_nova: isvIucData.valorViaturaNova,
            id_cidade: isvIucData.provincia,
            nome_cidade: isvIucData.nomeProvincia,
            id_municipio: isvIucData.municipio,
            nome_municipio: isvIucData.nomeMunicipio,

            iuc: valoresTotais.valorTotalIUC,
            iuc_cavalos_fiscais: valoresTotais.compsIUC.iucCavalosFiscais,
            iuc_cidade: valoresTotais.compsIUC.iucCidade,
            iuc_municipio: valoresTotais.compsIUC.iucMunicipio,
            iuc_percentagem_desconto_eletrico: valoresTotais.compsIUC.iucPercentagemDescontoEletrico,

            isv: valoresTotais.valorTotalISV,
            isv_base_imponible: valoresTotais.compsISV.isvBaseImponible,
            isv_base_imponible_final: valoresTotais.compsISV.isvBaseImponibleFinal,
            isv_comunidad: valoresTotais.compsISV.isvComunidad,
            isv_cuota_tributaria: valoresTotais.compsISV.isvCuotaTributaria,
            isv_minoracion: valoresTotais.compsISV.isvMinoracion,
            isv_porcentaje_aplicar: valoresTotais.compsISV.isvPorcentajeAplicar,
            isv_reduccion_base_imponible: valoresTotais.compsISV.isvReduccionBaseImponible,
            isv_total_pagar: valoresTotais.compsISV.isvTotalPagar,
            isv_valoracion_inicial: valoresTotais.compsISV.isvValoracionInicial,

            taxadeservico: valoresTotais.taxaDeServico,
            comissaodeservico: valoresTotais.comissao,
            comissao_credito: valoresTotais.taxaDeFinanciamento,
            check_comissao_credito: valoresTotais.taxaDeFinanciamentoAtivo,
            valor_chavenamao_particular: valoresTotais.valorPrecoChaveNaMaoParticular,
            valor_chavenamao_empresa: valoresTotais.valorPrecoChaveNaMaoEmpresa,
        };

        const dataCriarLink = {
            n: propostaNome,
            tipo_proposta: tipoProposta,
            link_token: linkToken,
            user_id: dataUser.iDTokenAuthenticated,
            user_token: dataUser.saveToken,
            array_dados_atualizados: listaDeDadosAtualizados,
        };


        axios.post(`${process.env.REACT_APP_CREATE_PROPOSTA_ES}`, dataCriarLink)
            .then((response) => {
                // console.log(response)
               
                if (response.data.sucesso) {

                    const nomeGerado = response.data.data.url_acesso

                    navigate(`/${t('menu.proposta')
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/ /g, '-')}/?n=${nomeGerado}&new=true`);   
                                 
                } else {
                    message.warning(response.data.mensagem)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };


    const voltarAPesquisar = () => {
        setUrlCarroLink('');
    };

    return (
        <div className="w-full flex gap-4 items-center justify-between">
            <div className="flex gap-4 items-center">
                <Button
                    type="primary"
                    htmlType="submit"
                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                    onClick={voltarAPesquisar}
                >
                    <HiRefresh /> Volver a la búsqueda
                </Button>
            </div>
            <Form layout="vertical" form={form}>
                <div className="form-gerar-proposta" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>

                    <Form.Item
                        rules={[{ required: true, message: 'Introduzca el nombre de la propuesta!' }]}
                    >
                        <Input
                            value={propostaNome}
                            onChange={(e) => setPropostaNome(e.target.value)}
                            placeholder="Introduzca el nombre de la propuesta"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Select
                            value={tipoProposta}
                            onChange={(value) => setTipoProposta(value)}
                        >
                            <Option value="proposta_cliente">Propuesta del cliente</Option>
                            <Option value="proposta_interna">Propuesta interna</Option>
                            <Option value="proposta_de_content">Propuesta de contenidos</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={isButtonDisabled}
                            className='flex gap-2 items-center bg-white text-black font-bold rounded'
                        >
                            Crear propuesta
                        </Button>
                    </Form.Item>

                </div>
            </Form>
        </div>
    );
};

