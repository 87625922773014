import React, { useEffect, useState } from 'react';
import { AutoComplete, message } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function PesquisaHubspot({ dataUser, data, setData }) {
    const { t } = useTranslation();

    // const [pesquisaEmailDeal, setPesquisaEmailDeal] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);

    // Pesquisa INPUT
    const handlePesquisaDealChange = (value) => {

        setData((prevData) => ({
            ...prevData,
            contactoSelecionado: '',
            pesquisaEmailDeal: value
        }));

        // Reseta o timeout anterior sempre que o valor mudar
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (value.length > 3) {
            const newTimeoutId = setTimeout(() => {

                let url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?email=${value}&token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&live`;

                axios.get(url)
                    .then((response) => {

                        if (response.data.sucesso) {
                            // console.log(response.data)
                            const email = response.data.contactos.map((item) => ({
                                value: item.properties.email,
                                label: `${item.properties.firstname} ${item.properties.lastname || ''} | (${item.properties.email}) | (${item.properties.hs_calculated_phone_number || item.properties.mobilephone || ''})`,
                                deals: item.deals,
                                id: item.id,
                            }));

                            // if (email.length == 0) {
                            //     message.warning('O Email selecionado não tem contacto associado.')
                            // }

                            if (response.data.contactos.length == 0){
                                message.warning('O Email selecionado não tem contacto associado.')
                                setData((prevData) => ({
                                    ...prevData,
                                    pesquisaEmailDeal: ''
                                }));
                                return
                            }

                            setData((prevData) => ({
                                ...prevData,
                                listaDeContactos: email, // Keep other data intact
                                proprsDoContacto: response.data.contactos[0].properties
                            }));

                        } else {

                        }


                    })
                    .catch((error) => {
                        console.error('Error occurred:', error);
                    });
            }, 1000); // Timeout de 1 segundo

            setTimeoutId(newTimeoutId)

        } else {
            setData((prevData) => ({
                ...prevData,
                listaDeContactos: [],
                proprsDoContacto: [],
            }));
        }
    };


    // Handle AutoComplete Selection
    const handlePesquisaDealSelect = (value) => {
        const selectedOption = data.listaDeContactos.find(option => option.value === value);
        // console.log(selectedOption)
        if (selectedOption) {

    
            setData((prevData) => ({
                ...prevData,
                contactoSelecionado: selectedOption.id,
            }));
        }
    };

    return (
        <>
            <div className="flex items-center gap-2">
                <AutoComplete
                    value={data.pesquisaEmailDeal}
                    options={data.listaDeContactos} // Correct array usage
                    onChange={handlePesquisaDealChange}
                    onSelect={handlePesquisaDealSelect}
                    placeholder={t('hubspot.pesquisa')}
                    style={{ width: '100%' }}
                />
            </div>
        </>
    );
}
