import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/Elements/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import Loading from '../../components/Elements/Loading';
import { FaHubspot } from 'react-icons/fa';
import PropostasPerfilDeal from '../../components/Hubspot/PerfilDeal/PropostasPerfilDeal';
import { FiExternalLink } from 'react-icons/fi';
import DocumentacaoPerfilDeal from '../../components/Hubspot/PerfilDeal/DocumentacaoPerfilDeal';
import { MdHistory } from "react-icons/md";


export default function PerfilDealHubspot({ dataUser }) {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();
    const parsed = queryString.parse(location.search); // Parâmetros
    const id_deal = parsed.id_deal; // ID do Deal get parametro

    const [data, setData] = useState({
        idDeal: '',
        dealsData: [],
        infoDealsData: [],
        propostasDealsData: [],
        documentosData: [],
    });

    const [drawerHistoricoRejeicao, setDrawerHistoricoRejeicao] = useState(false); // Open a drawer do Filtros


    useEffect(() => {

        if (id_deal) {
            const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&id_deal=${id_deal}&lang=${dataUser.language}&live`;

            axios.get(url)
                .then((response) => {
                    if (response.data.sucesso) {
                        setData((prevData) => ({
                            ...prevData,
                            idDeal: id_deal,
                            dealsData: response.data.deal,
                            infoDealsData: response.data.deal.properties,
                            propostasDealsData: response.data.deal.propostas,
                            documentosData: response.data.deal.documentacao,
                        }));
                    } else {
                        message.error(response.data.mensagem)
                        setTimeout(() => navigate('/hubspot'), 1000);
                    }
                })
                .catch((error) => {
                    console.error('Error occurred:', error);
                });
        }

    }, [id_deal, dataUser.saveToken, dataUser.iDTokenAuthenticated]);


    // Função para abrir o Drawer do Historico de Rejeicao 
    const showDrawerHistoricoRejeicao = () => {
        setDrawerHistoricoRejeicao(true);
    };

    //Corre todas as propostas e encontra a proposta aceite
    const propostaAceiteExistente = data.propostasDealsData.some(
        item => item.estado_proposta_hubspot === 'proposta_aceite'
    );


    if (!data.dealsData.id) {
        return (
            <Loading />
        );
    }

    return (
        <>
            {data.dealsData ? (
                <div className='card-hubspot grid gap-4'>
                    <TitleSection
                        title={t('perfil_do_deal.titulo_principal')}
                        description=""
                    />
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                        <p className='text-sm text-black font-semibold'>ID: <br /><span className='text-lg text-white font-semibold'>{data.infoDealsData.hs_object_id}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.nome')}: <br /><span className='text-lg text-white font-semibold capitalize'>{data.infoDealsData.dealname}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.email')}: <br /><span className='text-lg text-white font-semibold'>{data.dealsData?.contacto?.properties?.email}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.estado')}: <br /><span className='text-lg text-white font-semibold'>{data.infoDealsData.dealstage}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.pipeline')}: <br /><span className='text-lg text-white font-semibold'>{data.infoDealsData.pipeline}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.data_de_criacao')}: <br /><span className='text-lg text-white font-semibold'>{data.dealsData.createdAt}</span></p>
                        <p className='text-sm text-black font-semibold'>{t('hubspot.data_de_ultima_atualizacao')}: <br /><span className='text-lg text-white font-semibold'>{data.dealsData.updatedAt}</span></p>
                        {data.documentosData?.info?.tipo_contrato && (
                            <p className='text-sm text-black font-semibold'>{t('hubspot.tipo_contrato')}: <br /><span className='text-lg text-white font-semibold capitalize'>{data.documentosData?.info?.tipo_contrato}</span></p>
                        )}
                    </div>

                    <hr className='borda-white' />

                    <div className='flex justify-end'>
                        <Button href={data.infoDealsData.link_hubspot} target='_blank' type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded">
                            <FaHubspot />
                            {t('hubspot.aceder_ao_hubspot')}
                        </Button>
                    </div>
                </div>

            ) : null}

            <div className="grid gap-4">
                <div className="card">
                    <TitleSection
                        title={t('perfil_do_deal.propostas')}
                        description={data.propostasDealsData.length > 0 ? `${data.propostasDealsData.length} ${t('perfil_do_deal.propostas_disponiveis')}` : `${t('perfil_do_deal.nenhuma_propostas_disponiveis')}`}
                    />
                </div>
                {data?.propostasDealsData?.length > 0 && (
                    <>
                        <PropostasPerfilDeal
                            data={data}
                        />
                    </>
                )}
            </div>

            {propostaAceiteExistente && (
                <div className="card grid gap-4">
                    <TitleSection
                        title={t('perfil_do_deal_docs.titulo_principal')}
                        description={data.documentosData ? `Documentação disponível!` : "Documentação não enviada."}
                    />
                    {data.documentosData && (
                        <>
                            <DocumentacaoPerfilDeal
                                data={data}
                                setData={setData}
                                dataUser={dataUser}
                                drawerHistoricoRejeicao={drawerHistoricoRejeicao}
                                setDrawerHistoricoRejeicao={setDrawerHistoricoRejeicao}
                            />
                        </>
                    )}
                    <div className='flex justify-end gap-4'>
                        {data.documentosData?.historico_rejeicoes.length > 0 && (
                            <Button
                                type="primary"
                                onClick={showDrawerHistoricoRejeicao}
                                className="flex items-center gap-2 bg-white text-black font-bold rounded"
                            >
                                <MdHistory />
                                {t('hubspot.historico_documentacao')}
                            </Button>
                        )}
                        <Button href={data.dealsData.link_documentacao} target='_blank' type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded">
                            <FiExternalLink />
                            {t('hubspot.abrir_documentacao')}
                        </Button>
                    </div>
                </div>
            )}

        </>
    );
}
