import React, { useState, useEffect } from 'react';
import { Layout, Button, Popover, Modal } from 'antd';
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
// import { useNavigate } from 'react-router-dom';
import { IoMdNotifications, IoMdNotificationsOutline } from "react-icons/io";
import axios from 'axios';
import UserPopover from '../components/User/UserPopover';
import PopupNotificacoes from '../components/Notificacoes/PopupNotificacoes';
import flagPortugal from '../assets/flags/flag-portugal.svg'
import flagEspanha from '../assets/flags/flag-spain.svg'
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import i18n from "i18next";

const { Header } = Layout;

export default function CustomHeader({
  notificacaoState,
  collapsed,
  onToggle,
  dataUser,
  setDataUser
}) {

  const navigate = useNavigate();


  const [isPopoverVisible, setIsPopoverVisible] = useState(false); // popover de perfil
  const [notificacoesCarregadas, setNotificacoesCarregadas] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false); //popup de validação de alteração de linguagem

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    const currentLanguage = localStorage.getItem("language");
    // console.log("Idioma atual:", currentLanguage);

    if (currentLanguage === "pt") {
      localStorage.setItem("language", "es");
      setDataUser((prevData) => ({
        ...prevData,
        language: 'es',
      }));
      i18n.changeLanguage('es');
    } else {
      localStorage.setItem("language", "pt");
      setDataUser((prevData) => ({
        ...prevData,
        language: 'pt',
      }));
      i18n.changeLanguage('pt');
    }

    setIsModalVisible(false);
    // navigate("/");
  };


  const handlePopoverClick = async () => {
    setIsPopoverVisible(prev => !prev);
    if (!notificacoesCarregadas && !isPopoverVisible) {
      try {
        const url = `${process.env.REACT_APP_URL_NOTIFICACOES}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&aberta=1`;
        await axios.get(url);
        setNotificacoesCarregadas(true);
        // Presume-se que as notificações carregadas atualizam o estado global de notificações e contagem
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }
  };


  return (
    <Header className="flex justify-between items-center p-4 gap-4 header-main">
      <div className='flex items-center gap-4'>
        <Button type="primary" onClick={onToggle} className='h-full'>
          {collapsed ? <RiArrowRightSLine size={20} /> : <RiArrowLeftSLine size={20} />}
        </Button>
      </div>
      <div className='flex items-center gap-4'>
        <img
          src={dataUser.language == "pt" ? flagPortugal : flagEspanha}
          alt={dataUser.language == "pt" ? "Portugal" : "España"}
          className={`border-rounded ${dataUser.role === "administrator" ? "cursor-pointer" : ""}`}
          style={{
            width: '35px',
            height: '35px',
            border: '1px solid #fff',
            borderRadius: '50px',
            lineHeight: '37px',
            fontSize: '18px',
            padding: '4px',
          }}
          // onClick={dataUser.role === 'administrator' ? () => showModal() : undefined}
        />
        {Array.isArray(notificacaoState.notificacoes) && notificacaoState.notificacoes.length > 0 && (
          <Popover
            placement="bottomRight"
            content={<PopupNotificacoes notificacoes={notificacaoState.notificacoes} />}
            trigger="click"
            onClick={handlePopoverClick}
          >
            <div className="relative flex gap-2 items-center cursor-pointer">
              <div className="border border-[#575757] text-white py-[4px] px-[15px] rounded transition-colors duration-300 ease-in-out flex items-center">
                {isPopoverVisible ? (
                  <IoMdNotifications size={20} />
                ) : (
                  <IoMdNotificationsOutline size={20} />
                )}
                {notificacaoState.countNotificacoes > 0 && (
                  <div className="absolute top-[-10px] right-[-10px] bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                    {notificacaoState.countNotificacoes}
                  </div>
                )}
              </div>
            </div>
          </Popover>
        )}

        <UserPopover
          dataUser={dataUser}
        />

        <Modal
          title="Deseja alterar a linguagem?"
          open={isModalVisible}
          onCancel={handleCancel}
          onOk={handleConfirm}
          okText="Confirmar"
          cancelButtonProps={{ style: { display: "none" } }} // Remove o botão de cancelar
        >
          <div>
            <p>
              Para avançar, confirme por favor se deseja alterar para{" "}
              {dataUser.language === "pt" ? "Espanhol" : "Português"}.
            </p>
          </div>
        </Modal>


      </div>
    </Header>
  );
}
