import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { message } from 'antd';
import { Link } from 'react-router-dom';
import CustomTable from '../Tables/CustomTables';

export default function ContactoHubspot({ dataUser, data, setData }) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false); // Estado de loading

    useEffect(() => {
        if (data.contactoSelecionado) {

            setLoading(true);

            const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&id_contacto=${data.contactoSelecionado}&lang=${dataUser.language}&live`;

            axios.get(url)
                .then((response) => {

                    if (response.data.sucesso) {
                        setData((prevData) => ({
                            ...prevData,
                            listaDeDeals: response.data.contactos[0].deals || [],
                        }));
                    } else {
                        message.error(response.data.mensagem)
                    }

                })
                .catch((error) => {
                    console.error('Error occurred:', error);
                })
                .finally(() => {
                    setLoading(false); // Finaliza o loading
                });
        }
    }, [data.contactoSelecionado, dataUser.saveToken, dataUser.iDTokenAuthenticated]);

    const allColumns = [
        {
            title: t('hubspot.nome_deal'), // Correct property syntax
            key: "nome_deal", // Ensure unique key
            render: (deal) => (
                <Link
                    className='hover:underline hover:text-white'
                    to={`/${t('menu.hubspot')
                        .toLowerCase()
                        .normalize('NFD') // Normaliza a string para separar acentos
                        .replace(/[\u0300-\u036f]/g, '') // Remove os acentos
                        .replace(/ /g, '-')}/${t('menu.perfil-deal')
                            .toLowerCase()
                            .normalize('NFD') // Normaliza a string para separar acentos
                            .replace(/[\u0300-\u036f]/g, '') // Remove os acentos
                            .replace(/ /g, '-') // Substitui os espaços por hífens
                        }?id_deal=${deal.id}`}>
                    {deal.properties.dealname}
                </Link>
            ),
        },
        {
            title: t('hubspot.data_de_criacao'), // Start of new column object
            key: "data_deal",
            render: (deal) => <span>{deal.createdAt}</span>,
        },
        {
            title: t('hubspot.estado'),
            key: "estado_deal", // Changed to a unique key
            render: (deal) => <span>{deal.properties.dealstage_name}</span>,
        },
        {
            title: t('hubspot.pipeline'),
            key: "pipeline_deal", // Changed to a unique key
            render: (deal) => <span>{deal.properties.pipeline_name}</span>,
        },
        // Add more columns as needed
    ];


    return (
        <>
            <div className='text-white grid gap-4'>
                <h1 className='text-sm font-normal'>{t('hubspot.resultado_da_pesquisa')}: {data.proprsDoContacto.email}</h1>
                <p><strong>{data.proprsDoContacto.firstname}</strong> | <strong>{data.proprsDoContacto.phone}</strong></p>
                <hr />
            </div>
            <CustomTable
                columns={allColumns}
                data={data.listaDeDeals.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={false}
                loading={loading}
            />
        </>
    );
}
