import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Spin, Layout, Button, message, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";
import i18n from "i18next";
import './styles/dark-theme.css';
import './styles/index.css';
import CustomSidebar from './layouts/sidebar';
import CustomHeader from './layouts/header';
import CustomFooter from './layouts/footer';
import { AppRoutes } from './Routes';
import Login from './pages/Login/Login';
import logo from './assets/logo-branco 2.svg';


const { Content } = Layout;

export default function App() {
    const navigate = useNavigate();

    // Estado para os dados do usuário
    const [dataUser, setDataUser] = useState({
        isAuthenticated: false,
        userData: null,
        validToken: false,
        validLogin: false,
        saveToken: '',
        iDTokenAuthenticated: '',
        cargo_select: '',
    });

    // Estado único para notificações
    const [notificacaoState, setNotificacaoState] = useState({
        notificacoes: [],
        countNotificacoes: 0,
    });

    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(true);

    const [isMobileView, setIsMobileView] = useState(false);
    const [ignoreMobileWarning, setIgnoreMobileWarning] = useState(false);
    const [codeInput, setCodeInput] = useState(''); // Estado para armazenar o valor do input

    // Função para verificar o token e autenticar o usuário
    const authenticateUser = async () => {

        const token = localStorage.getItem('token');
        const loggedInUser = localStorage.getItem("user");

        if (token && loggedInUser) {
            const userId = JSON.parse(loggedInUser).ID;

            try {
                // Faz apenas um pedido para validar e recuperar dados do utilizador
                const response = await axios.post(
                    `${process.env.REACT_APP_LOGIN_VALIDATE}`,
                    { token, userId, version: `${process.env.REACT_APP_VERSION}` }
                );

                if (response.data.sucesso) {
                    const user = response.data.user;

                    // Recupera o idioma do utilizador ou define o padrão
                    const userLanguage = (user.data.pais && user.data.pais.length > 0)
                        ? user.data.pais[0]
                        : 'pt'; // Fallback para 'pt' se `pais` estiver indefinido ou vazio

                    // Definir a linguagem no i18n com fallback de segurança
                    try {
                        i18n.changeLanguage(userLanguage);
                    } catch (i18nError) {
                        console.error('Erro ao mudar o idioma:', i18nError);
                        i18n.changeLanguage('pt'); // Fallback adicional para português
                    }

                    // Atualiza o `localStorage` e o estado do utilizador
                    localStorage.setItem('language', userLanguage);
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));

                    setDataUser({
                        isAuthenticated: true,
                        userData: user,
                        validLogin: true,
                        saveToken: token,
                        iDTokenAuthenticated: userId,
                        language: userLanguage,
                        role: user.roles[0],
                        cargo_select: user.data.cargo_select,
                    });

                } else {
                    message.warning(response.data.mensagem)
                    handleLogout(); // Se a autenticação falhar, limpa a sessão
                }

            } catch (error) {

                handleLogout(); // Limpa a sessão em caso de erro

            } finally {
                setLoading(false);
            }

        } else {
            setLoading(false); // Se não houver token ou utilizador, define loading como false
        }
    };

    // Função para limpar sessão e `localStorage` ao falhar a autenticação
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('language');
        setDataUser({ isAuthenticated: false, userData: null, validToken: false });
        navigate('/'); // Redireciona para a página de login se necessário
    };



    // Detectar quando a tela está abaixo de 1024px de largura
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1024);
        };

        handleResize(); // Checar no início
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    // Efeito para autenticar usuário ao carregar a página
    useEffect(() => {

        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage); // Ajustar a linguagem se já estiver armazenada
        }

        authenticateUser();

    }, []);

    // Efeito para buscar notificações do usuário autenticado
    useEffect(() => {
        if (dataUser.isAuthenticated && dataUser.saveToken && dataUser.iDTokenAuthenticated) {
            axios.get(`${process.env.REACT_APP_URL_NOTIFICACOES}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&limit=5`)
                .then(response => {

                    setNotificacaoState({
                        notificacoes: response.data.data.notificacoes,
                        countNotificacoes: response.data.data.contagem
                    });
                })
                .catch(error => console.error('Erro ao buscar notificações:', error));
        }
    }, [dataUser.isAuthenticated, dataUser.saveToken, dataUser.iDTokenAuthenticated]);

    // Função para alternar colapso do Sidebar
    const toggleCollapsed = () => setCollapsed(!collapsed);

    if (loading) {
        return (
            <div className="flex h-screen justify-center items-center">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
        );
    }



    // if (isMobileView && !ignoreMobileWarning) {
    //     return (
    //         <div className="flex h-screen justify-center items-center flex-col gap-6 p-4">
    //             <img src={logo} alt="Logo" />
    //             <p className='text-white my-4 text-center'>Plataforma disponível apenas em Desktop.</p>
    //         </div>
    //     );
    // }


    // Exibir mensagem de visualização em desktop se estiver abaixo de 1024px e o usuário não ignorou a mensagem
    if (isMobileView && !ignoreMobileWarning) {
        return (
            <div className="flex h-screen justify-center items-center flex-col gap-6 p-4">
                <img src={logo} style={{ height: 'inherit' }} alt="Logo" />
                <p className='text-white my-4 text-center'>Plataforma disponível apenas em Desktop.</p>
                <div className="flex flex-col gap-4 items-center">
                    <Input
                        placeholder="Digite o código"
                        value={codeInput}
                        onChange={(e) => setCodeInput(e.target.value)}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            if (codeInput === '1999') {
                                setIgnoreMobileWarning(true);
                            } else {
                                alert('Código incorreto!');
                            }
                        }}
                    >
                        Continuar
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {!dataUser.isAuthenticated ? (
                <div className={`max-w-screen-xl m-auto`}>
                    <div className={`w-full flex flex-col justify-center items-center gap-4 p-2 h-screen`}>
                        <div className={`w-full flex flex-col gap-2 md:gap-4 text-white`}>
                            <Login />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <CustomSidebar
                        collapsed={collapsed}
                        currentLanguage={i18n.language}
                        dataUser={dataUser}
                    />
                    <Layout className="site-layout">
                        <CustomHeader
                            collapsed={collapsed}
                            onToggle={toggleCollapsed}
                            notificacaoState={notificacaoState}
                            dataUser={dataUser}
                            setDataUser={setDataUser}
                        />
                        <Content className="main-content">
                            <div className="max-w-screen-2xl m-auto grid gap-4">
                                {/* <div className='card'>
                                    <Button type="primary" onClick={() => navigate(-1)}>
                                        Voltar
                                    </Button>
                                </div> */}
                                <AppRoutes
                                    dataUser={dataUser}
                                    notificacaoState={notificacaoState}
                                />
                            </div>
                        </Content>
                        <CustomFooter dataUser={dataUser} />
                    </Layout>
                </>
            )}
        </Layout>
    );
}
