import React from 'react'
import { Button, Modal } from 'antd';
import { RiFilePaperFill } from "react-icons/ri";
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ModalPerfilDeal({ hubspotInfo, openModalPerfilDeal, setOpenModalPerfilDeal }) {

    const { t } = useTranslation();

    const handleOkPerfilDeal = () => setOpenModalPerfilDeal(false);
    const handleCancelPerfilDeal = () => setOpenModalPerfilDeal(false);

    return (
        <>
            <Modal
                title={<span className="flex items-center gap-2">{t('hubspot.perfil_deal')}</span>}
                open={openModalPerfilDeal}
                onOk={handleOkPerfilDeal}
                onCancel={handleCancelPerfilDeal}
                okText={t('hubspot.fechar')}
                footer={null}
            >
                <div className='grid gap-4'>
                    <div>
                        <p className="text-black text-base flex justify-between">
                            {t('hubspot.cliente')}: <span className="font-bold capitalize">{hubspotInfo.nomeDoClienteDeal}</span>
                        </p>
                        <p className="text-black text-base flex justify-between">
                            {t('hubspot.email')}: <span className="font-bold">{hubspotInfo.emailDoClienteDeal}</span>
                        </p>
                        <p className="text-black text-base flex justify-between">
                            {t('hubspot.etapa')}: <span className="font-bold">{hubspotInfo.etapaDoDeal}</span>
                        </p>
                        <p className="text-black text-base flex justify-between">
                            {t('hubspot.pipeline')}: <span className="font-bold">{hubspotInfo.pipelineDoDeal}</span>
                        </p>
                    </div>
                    <hr />
                    <div className="flex justify-end">
                        <Link to={`/${t('menu.hubspot').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-')}/${t('hubspot.perfil_deal').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-')}/?id_deal=${hubspotInfo.idDoDeal}`}>
                            <Button
                                type="primary"
                                target="_blank"
                                className="flex gap-2 items-center bg-white text-black font-bold rounded"
                            >
                                <FaEye />
                                {t('hubspot.ver_perfil')}
                            </Button>
                        </Link>

                    </div>
                </div>
            </Modal>
        </>
    );
}
