import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import React from 'react';
import { Button, Tabs } from 'antd';
import Loading from '../../Elements/Loading';
import { HiOutlineDownload } from 'react-icons/hi';

export async function downloadDocuments(links) {
    const zip = new JSZip();
    const folder = zip.folder("Documentos");

    const promises = Object.entries(links).map(async ([key, link]) => {

        //console.log(link)


        const response = await fetch(`https://${link}`);
        const blob = await response.blob();
        folder.file(`${key}.pdf`, blob);
    });

    await Promise.all(promises);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "Documentos.zip");
}

const DownloadDocumentos = ({ loading, data }) => {


    const handleDownloadAll = () => {

        if (data?.linkDownload) {
            downloadDocuments(data.linkDownload);
        }
    };

    const hasMultipleDocuments = data?.linkDownload && Object.keys(data.linkDownload).length > 1;


    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                data.linkDownload && Object.keys(data.linkDownload).length > 0 && (
                    <>
                        <hr className="mb-[24px]" />
                        <div className='flex gap-4 justify-between'>
                            <p className="text-sm font-normal text-white">
                                Documentos gerados com sucesso! Valide todos os campos:
                            </p>
                            {hasMultipleDocuments && (
                                <Button
                                    className="flex items-center shadow-none font-bold text-black bg-white"
                                    icon={<HiOutlineDownload />}
                                    onClick={handleDownloadAll}
                                >
                                    Download dos Documentos
                                </Button>
                            )}
                        </div>

                        <Tabs>
                            {Object.entries(data.linkDownload).map(([key, link]) => (
                                <Tabs.TabPane
                                    tab={<span>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}</span>}
                                    key={key}
                                >
                                    <iframe
                                        src={`https://${link}`}
                                        title={`PDF Viewer - ${key}`}
                                        width="100%"
                                        height="800px"
                                        style={{ border: "none" }}
                                    ></iframe>
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </>
                )
            )}
        </>
    );
};

export default DownloadDocumentos;
