import React, { useState } from "react";
import TitleSection from "../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import TableDocumentosGerados from "../../components/GeradorDocumentos/TableDocumentosGerados";


export default function VerDocumentos({ dataUser }) {

    const { t } = useTranslation();

    return (
        <>
            <div className='card grid gap-4'>
                <TitleSection
                    title={t('ver_documentos.titulo_principal')}
                    description={t('ver_documentos.sub_titulo')}
                />
                <TableDocumentosGerados
                    dataUser={dataUser}
                />
            </div>
        </>
    );
}