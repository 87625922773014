import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiRefresh } from 'react-icons/hi';
import CardDestaque from '../../AllCards/CardDestaque';
import { FaCheckCircle } from 'react-icons/fa';


export default function DashboardParaAdmins({ data, filter }) {

    const { t } = useTranslation();


    return (
        <>
            <div className="grid grid-cols-4 gap-4 w-full">

                <CardDestaque
                    text={t('dashboard.propostas_criadas_hoje')}
                    number={data.destaques?.atual?.propostas_geradas}
                    comparadorNumero={data.destaques?.comparacao?.propostas_geradas_numero}
                    comparadorPercentagem={data.destaques?.comparacao?.propostas_geradas_percentagem}
                    icon={<HiRefresh size={20} />}
                    style={false}
                    labelDataQuadro={filter.labelDataQuadro}
                    comparar={data.comparar_com}
                />

                <CardDestaque
                    text={t('dashboard.propostas_aceites_hoje')}
                    number={data.destaques?.atual?.propostas_aceites}
                    comparadorNumero={data.destaques?.comparacao?.propostas_aceites_numero}
                    comparadorPercentagem={data.destaques?.comparacao?.propostas_aceites_percentagem}
                    icon={<FaCheckCircle size={20} />}
                    style={false}
                    labelDataQuadro={filter.labelDataQuadro}
                    comparar={data.comparar_com}
                />

                <CardDestaque
                    text="Média Taxa de Serviço"
                    number={data.destaques?.atual?.propostas_aceites}
                    comparadorNumero={data.destaques?.comparacao?.propostas_aceites_numero}
                    comparadorPercentagem={data.destaques?.comparacao?.propostas_aceites_percentagem}
                    icon={<FaCheckCircle size={20} />}
                    style={false}
                    labelDataQuadro={filter.labelDataQuadro}
                    comparar={data.comparar_com}
                />

                <CardDestaque
                    text="Média Taxa de Serviço"
                    number={data.destaques?.atual?.propostas_aceites}
                    comparadorNumero={data.destaques?.comparacao?.propostas_aceites_numero}
                    comparadorPercentagem={data.destaques?.comparacao?.propostas_aceites_percentagem}
                    icon={<FaCheckCircle size={20} />}
                    style={false}
                    labelDataQuadro={filter.labelDataQuadro}
                    comparar={data.comparar_com}
                />


            </div>

            <div className="grid grid-cols-1 gap-4 w-full">

                <p className='text-white text-sm font-medium'>Proposta Aceites</p>

            </div>

        </>
    )
};
