import React, { useState } from 'react';
import { Table, Radio, Button, message, Spin } from 'antd';
import axios from 'axios';
import logoCor from '../../../assets/hubspot-icon.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function TableDealsHubpotProposta({
    listaDeDeals,
    dataUser,
    dataCarros,
    setIsModalOpen,
    setHubspotInfo,
    setPropostaInfo,
    propostaInfo,
    tituloCTA
}) {

    const { t } = useTranslation();


    const [selectedRowKeys, setSelectedRowKeys] = useState([]); //radiobutton select da tab dos deals
    const [loading, setLoading] = useState(false);
    

    //Função para atualizar info da Proposta
    const atualizarInfoDaProposta = () => {
        let url = `${process.env.REACT_APP_GET_HUBSPOT_INFO_DA_PROPOSTA}?id_proposta=${propostaInfo.idProposta}&token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&live`;
        axios.get(url)
            .then((response) => {

                if (response.data.sucesso) {
                    const allHubspot = response.data.hubspot

                    setHubspotInfo((prevData) => ({
                        ...prevData,
                        idDoDeal: allHubspot.deal.id_deal_hubspot,
                        nomeDoClienteDeal: allHubspot.contacto.firstname,
                        emailDoClienteDeal: allHubspot.contacto.email,
                        etapaDoDeal: allHubspot.deal.dealstage_name,
                        pipelineDoDeal: allHubspot.deal.pipeline_name,
                    }));
                }


            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    }



    // Função para associar o deal
    const handleAssociarDeal = () => {

        if (selectedRowKeys.length > 0) {

            setLoading(true)

            const url = `${process.env.REACT_APP_GET_ASSOCIAR_DEAL}?id_proposta=${propostaInfo.idProposta}&id_deal=${selectedRowKeys}&token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&live`;

            axios
                .get(url)
                .then((response) => {
                    if (response.data.sucesso) {
                        message.success(response.data.data.mensagem)
                        atualizarInfoDaProposta()

                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            estadoDaProposta: response.data.data.estado_proposta_hubspot,
                        }));

                        setLoading(false)

                        setTimeout(() => {
                            setIsModalOpen(false);
                        }, 500);

                    } else {
                        message.error(response.data.data.mensagem)
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    console.error('Erro ao associar deal:', error);
                    message.error('Erro ao associar deal. Tente novamente!')
                });
        } else {
            setLoading(false)
            console.error('Nenhuma linha selecionada');
            message.warning('Seleciona um Deal.');
        }
    };



    // Função para lidar com a seleção de uma linha
    const onSelectChange = (selectedRowKey) => {
        setSelectedRowKeys([selectedRowKey]);
    };


    return (
        <div className='grid gap-4'>
            <Table
                dataSource={listaDeDeals}
                rowKey={(deal) => deal.id}
                scroll={{ x: 'max-content' }}
                bordered
                pagination={false}
            >
                <Table.Column
                    width={70}
                    title=""
                    key="select"
                    render={(deal) => (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Radio
                                checked={selectedRowKeys.includes(deal.id)}
                                onChange={() => onSelectChange(deal.id)}
                            />
                        </div>
                    )}
                />
                <Table.Column
                    title={t('tables.nome_cliente')}
                    key="nome_deal"
                    render={(deal) => <span>{deal.properties.dealname}</span>}
                />
                <Table.Column
                    title={t('tables.data_deal')}
                    key="data_deal"
                    render={(deal) => <span>{deal.createdAt}</span>}
                />
            </Table>

            <div className='flex items-center justify-end gap-2'>
                <Button
                    type="primary"
                    className="flex gap-2 hubspot-btn items-center font-bold rounded"
                    onClick={handleAssociarDeal}
                >
                    <img
                        src={logoCor}
                        alt="Logo"
                        className="w-6 object-contain"
                    />
                    {tituloCTA}
                </Button>
            </div>

            {loading ? (
                <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                    <Spin indicator={antIcon} />
                    <p className='text-black text-center'>{t('gerar_proposta.a_associar')}...</p>
                </div>
            ) : null}

        </div>
    );
}
