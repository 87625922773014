import React from 'react';
import { Button, Input, message, Form, Switch } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function FormAlterarExpress({
    dataUser,
    propostaInfo,
    valoresTotais,
    setValoresTotais,
    formatarNumero
}) {
    const { t } = useTranslation();

    // Handle Switch change for Express option
    const handleExpress = (checked) => {
        setValoresTotais((prevData) => ({
            ...prevData,
            valorExpressAtivo: checked ? 1 : 0,
        }));
    };

    // Handle input change for Express value
    const handleValueChange = (e) => {
        const { value } = e.target;
        setValoresTotais((prevData) => ({
            ...prevData,
            valorExpress: value,
        }));
    };

    // Function to submit the updated proposal
    const finalizarEdicaoDeProposta = (values) => {

        const dados = {
            comissao_express: values.valorExpress || valoresTotais.valorExpress,
            check_comissao_express: values.valorExpressAtivo ? 1 : 0,
        };

        const data = {
            id_proposta: propostaInfo.idProposta,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
            array_dados: dados,
        };

        axios.post(`${process.env.REACT_APP_URL_EDITAR_PROPOSTA}`, data)
            .then((response) => {
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(() => {
                message.error(t('editar_proposta.error'));
            });
    };

    // Calculation function for Chave na Mão Empresa with Express
    const calculoChaveNaMaoEmpresaComExpress = () => {
        if (valoresTotais.valorExpressAtivo === 0) {
            const resultado = valoresTotais.valorPrecoChaveNaMaoEmpresa + (valoresTotais.valorExpress || 0);
            return `${formatarNumero(resultado)}`;
        }

        return `${formatarNumero(valoresTotais.valorPrecoChaveNaMaoEmpresa)}`;
    };

    const calculoChaveNaMaoParticularComExpress = () => {
        if (valoresTotais.valorExpressAtivo === 0) {
            const resultado = valoresTotais.valorPrecoChaveNaMaoParticular + (valoresTotais.valorExpress || 0);
            return `${formatarNumero(resultado)}`;
        }

        return `${formatarNumero(valoresTotais.valorPrecoChaveNaMaoParticular)}`;
    };


    return (
        <Form
            layout="vertical"
            onFinish={finalizarEdicaoDeProposta}
            initialValues={{
                valorExpress: valoresTotais.valorExpress,
                valorExpressAtivo: valoresTotais.valorExpressAtivo,
            }}
        >
            <div className='flex flex-col items-stretch gap-2'>
                <div className={`flex items-center justify-between gap-2 express-class ${valoresTotais.valorExpressAtivo === 1 ? '' : 'mb-6'}`}>
                    <p className='font-semibold'>{t('editar_proposta.express')}</p>
                    <Form.Item name="valorExpressAtivo" valuePropName="checked">
                        <Switch onChange={handleExpress} />
                    </Form.Item>
                </div>

                {/* Conditionally render the valorExpress input and calculations based on valorExpressAtivo */}
                {valoresTotais.valorExpressAtivo === 1 && (
                    <>
                        <Form.Item name="valorExpress">
                            <Input
                                placeholder={t('editar_proposta.taxa_servico')}
                                value={valoresTotais.valorExpress}
                                onChange={handleValueChange}
                                type="number"
                            />
                        </Form.Item>

                        <div className='grid gap-4 text-md border-card bg-[#e8e8e8] p-4 mb-6'>

                            <div>
                                <p className='font-semibold'>{t('editar_proposta.preco_chave_na_mao_particular_express')}</p>
                                <p>{calculoChaveNaMaoParticularComExpress()}</p>
                            </div>

                            <div>
                                <p className='font-semibold'>{t('editar_proposta.preco_chave_na_mao_empresa_express')}</p>
                                <p>{calculoChaveNaMaoEmpresaComExpress()}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="shadow-none font-bold text-black bg-green-400">
                    {t('editar_proposta.guardar')}
                </Button>
            </Form.Item>
        </Form>
    );
}
