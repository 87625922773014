import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'antd';

export default function ExpressInfoModal({ }) {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <AiOutlineInfoCircle className="icon-question" onClick={showModal} />

            <Modal
                title={"Informações sobre o Importrust Express"}
                open={isModalVisible}
                onOk={handleCancel}
                onCancel={handleCancel}
                okText="Fechar"
                footer={null}
            >
                <div className='grid gap-4'>
                    <p>
                        O Importrust Express é o serviço ideal para quem procura uma entrega mais rápida do seu novo carro, sem comprometer a segurança e a qualidade.
                    </p>
                    <p>
                        Com o Importrust Express, pode ter o seu carro em menos de 2 semanas, graças à nossa parceria com um operador logístico de confiança, que assegura o transporte do veículo desde o país de origem.
                    </p>
                    <p>
                        O processo é feito com o carro em circulação, garantindo uma entrega eficiente e segura (incluindo seguro de transporte).
                    </p>
                    <p>
                        O Importrust Express reduz a duração do processo de 30-40 dias para apenas 15.
                    </p>
                </div>

            </Modal>
        </>
    );
}
