import React from "react";
import { useTranslation } from "react-i18next";
import { TbFaceIdError } from "react-icons/tb";


export default function NotFound() {

    const { t } = useTranslation();

    return (
        <div className="card text-white flex flex-col justify-center items-center gap-4 text-center">
            <TbFaceIdError size={40} />
            <h1 className="text-center text-lg font-bold">UPS...</h1>
            <span>{t('error.frase')}.</span>
        </div>
    )
}
