import React, { useState, useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';


export default function DuracaoDeProcesso({ valoresTotais, propostaInfo }) {

    const { t } = useTranslation();

    // Estados para armazenar os valores de tempo
    const [tempoRecolha, setTempoRecolha] = useState(`${propostaInfo.outros.tempo_recolha || '?'} semanas`);
    const [tempoEntrega, setTempoEntrega] = useState(`${propostaInfo.outros.tempo_entrega || '?'} semanas`);
    const [estimativaEntrega, setEstimativaEntrega] = useState(`${propostaInfo.outros.estimativa_entrega || '?'} semanas`);
    const [corTexto, setCorTexto] = useState('');
    const [corTextoFinal, setCorTextoFinal] = useState('');


    useEffect(() => {
        if (valoresTotais.valorExpressAtivo === 1) {
            setTempoRecolha('5 dias');
            setTempoEntrega('10 dias');
            setEstimativaEntrega('15 dias');
            setCorTexto('text-[#f8a81b]');
            setCorTextoFinal('text-[#269f0a]');
        } else {
            setTempoRecolha(`${propostaInfo.outros.tempo_recolha} semanas`);
            setTempoEntrega(`${propostaInfo.outros.tempo_entrega} semanas`);
            setEstimativaEntrega(`${propostaInfo.outros.estimativa_entrega} semanas`);
            setCorTexto('');
            setCorTextoFinal('text-[#269f0a]');
        }
    }, [valoresTotais.valorExpressAtivo, propostaInfo.outros]);

    return (
        <div className='card grid gap-4'>
            <div className="flex justify-between items-center gap-4">
                <div className="grid gap-1">
                    <p className="text-lg font-semibold">{t('duracao_processo.titulo_principal')}</p>
                </div>
            </div>
            <hr></hr>
            <div className='grid gap-4'>
                <div className='grid gap-4'>
                    <div className='flex flex-col gap-4'>
                        <div className='flex justify-between items-center'>
                            <div className='flex gap-2 items-center'>
                                <p className='font-normal text-sm'>
                                    {t('duracao_processo.tempo_de_recolha')}
                                </p>
                                <Tooltip title={t('duracao_processo.tempo_de_recolha_desc')}>
                                    <AiOutlineInfoCircle className='icon-question' />
                                </Tooltip>
                            </div>
                            <span className={`font-bold text-xs sm:text-sm ${corTexto}`}>
                                {tempoRecolha}
                            </span>
                        </div>

                        <div className='flex justify-between items-center'>
                            <div className='flex gap-2 items-center'>
                                <p className='font-normal text-sm'>
                                    {t('duracao_processo.tempo_de_entrega')}
                                </p>
                                <Tooltip title={t('duracao_processo.tempo_de_entrega_desc')}>
                                    <AiOutlineInfoCircle className='icon-question' />
                                </Tooltip>
                            </div>
                            <span className={`font-bold text-xs sm:text-sm ${corTexto}`}>
                                {tempoEntrega}
                            </span>
                        </div>

                        <hr></hr>

                        <div className='flex justify-between items-center'>
                            <div className='flex gap-2 items-center'>
                                <p className='text-base font-semibold'>
                                    {t('duracao_processo.tempo_total')}
                                </p>
                                <Tooltip title={t('duracao_processo.tempo_total_desc')}>
                                    <AiOutlineInfoCircle className='icon-question' />
                                </Tooltip>
                            </div>
                            <span className={`font-bold text-xs sm:text-sm ${corTextoFinal}`}>
                                {estimativaEntrega}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
