import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Tooltip, Button } from 'antd';
import { FaEye } from 'react-icons/fa';
import CustomTable from '../Tables/CustomTables';
import CustomColumns from '../Tables/CustomColumns';
import CopyIcon from '../Elements/CopyIcon';


export default function TableHistoricoPropostas({ dataUser }) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false); // Estado de loading

    const [data, setData] = useState({
        propostas: [],
    });

    // const [configsTable, setConfigsTable] = useState({
    //     modalColunas: false,
    // });

    // State to control visible columns
    const [visibleColumns, setVisibleColumns] = useState([
        'data_criacao',
        'user',
        'url'
    ]);


    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            let url = `${process.env.REACT_APP_GET_HISTORICO_PESQUISAS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}`;

            try {
                const response = await axios.get(url);
                setData({
                    propostas: response.data,
                });
                setLoading(false);
            } catch (error) {
                console.error('Error occurred:', error);
            }
        };

        fetchData();

    }, [dataUser.iDTokenAuthenticated]);


    const allColumns = [
        {
            title: t('tables.data'),
            key: 'data_criacao',
            width: 250,
            render: (proposta) => (
                <p>{proposta.data_criacao}</p>
            ),
        },
        {
            title: t('tables.nome'),
            key: 'user',
            width: 250,
            render: (proposta) => (
                <p>{proposta.user_data.nome_criou}</p>
            ),
        },
        {
            title: 'URL',
            key: 'url',
            width: 250,
            render: (proposta) => (
                <div className="flex items-center gap-1">
                    <Tooltip placement="topRight" title={proposta.url}>
                        {proposta.url.slice(0, 30)}...{proposta.url.slice(-5)}
                    </Tooltip>
                    <CopyIcon text={proposta.url} />
                </div>
            ),
        },
    ].filter(Boolean);


    //Gestão das Colunas
    const columns = allColumns.filter(col => visibleColumns.includes(col.key));
    //Abrir popup para gerir as Colunas
    // const showModal = () => {
    //     setConfigsTable((prevState) => ({
    //         ...prevState,
    //         modalColunas: true,  // Define modalColunas como false
    //     }));
    // };


    return (
        <>
            {/* <div className='flex justify-end'>
                <Button
                    type="primary"
                    onClick={showModal}
                    className='shadow-none font-bold text-black bg-white'>
                    <div className='flex items-center gap-2'>
                        <FaEye size={20} style={{ cursor: 'pointer' }} />
                        <p>Colunas</p>
                    </div>
                </Button>
            </div> */}
            <CustomTable
                columns={columns}
                data={data.propostas.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={{ pageSize: 20, showSizeChanger: false,  position: ['bottomCenter'] }}
                loading={loading}
            />
            {/* <CustomColumns
                allColumns={allColumns}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                configsTable={configsTable}
                setConfigsTable={setConfigsTable}
            /> */}

        </>
    )
};
