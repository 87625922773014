import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/Elements/Title';
import TableHistoricoPropostas from '../../components/HistoricoPropostas/TableHistoricoPropostas';

export default function HistoricoPropostas({ dataUser }) {

    const { t } = useTranslation();


    return (
        <div className='card grid gap-4'>
            <TitleSection
                title={t('historico.titulo_principal')}
                description={t('historico.sub_titulo')}
            />
            <TableHistoricoPropostas dataUser={dataUser} />
        </div>
    );
};

