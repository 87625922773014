import React from 'react';

export default function TitleSection({ title, description }) {


    return (
        <div className='grid gap-4'>
            <div className='grid gap-1 text-white'>
                <p className='text-lg font-bold'>{title}</p>
                {description && (
                    <p className='text-sm font-normal'>{description}</p>
                )}
            </div>
            <hr></hr>
        </div>
    );
}
