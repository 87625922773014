import React, { useState } from 'react';
import axios from 'axios';
import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';


export default function LoginForm() {

    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);

    const handleLogin = async () => {

        setLoginLoading(true);
        setLoginSuccess(false);

        try {

            const response = await axios.post(
                `${process.env.REACT_APP_LOGIN}`,
                {
                    username,
                    password,
                },
                { withCredentials: true }
            );

            if (response.data.sucesso) {

                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('token', response.data.token);

                message.success(t('login.login_sucesso'));

                setTimeout(() => {
                    window.location.reload();
                }, 1000);

            } else {

                message.error(response.data.mensagem)

            }

        } catch (error) {

            console.log(error)

        } finally {

            setLoginLoading(false);

        }
    };

    return (
        <div className="sec-geral-top">
            <div className="form main flex flex-col gap-4">
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={() => handleLogin()}
                >
                    <p className='label-do-campo text-black pb-2'>{t('login.username')}</p>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: `${t('loginAlert.username')}`,
                            },
                        ]}
                    >
                        <Input
                            className="input-geral"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);

                            }} autoComplete="username"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={t('login.username')}
                        />
                    </Form.Item>
                    <p className='label-do-campo text-black pb-2'>{t('login.password')}</p>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: `${t('loginAlert.password')}`,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-geral"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            autoComplete="current-password"
                            placeholder={t('login.password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-black w-full h-full text-white font-bold rounded"
                            disabled={loginLoading || loginSuccess} // Desativa o botão se estiver em loading ou sucesso
                        >
                            {loginLoading ? (
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
                            ) : loginSuccess ? (
                                <div className="inline-flex items-center text-lg">
                                    <IoMdCheckmarkCircleOutline className="text-green-500" style={{ fontSize: 24, marginRight: '8px' }} />
                                    <span className="font-bold text-sm mx-1">A entrar... Aguarde</span>
                                </div>
                            ) : (
                                <span className="font-bold text-sm">{t('login.submit')}</span>
                            )}
                        </Button>

                    </Form.Item>
                </Form>
            </div>
            <div className='text-xs flex flex-col items-center'>
                <span className='text-center'>
                    Para solicitar acesso ao Dashboard Importrust, <br />
                    <a className='underline' href='mailto:carlos.costa@importrust.com'>
                        entre em contato com a Equipa Tech da Importrust
                    </a>.
                </span>
            </div>
        </div>
    );
}

