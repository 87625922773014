import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import PesquisaHubspot from '../../components/Hubspot/PesquisaHubspot';
import TitleSection from '../../components/Elements/Title';
import ContactoHubspot from '../../components/Hubspot/ContactoHubspot';

export default function PerfilContactoHubspot({ dataUser }) {

    const { t } = useTranslation();

    const [data, setData] = useState({
        listaDeContactos: [],
        contactoSelecionado: '',
        proprsDoContacto: [],
        listaDeDeals: [],
        pesquisaEmailDeal: '',
    });

    return (
        <>
            <div className="card-hubspot grid gap-4">
                <TitleSection
                    title={t('hubspot.titulo_principal')}
                    description={t('hubspot.sub_titulo')}
                />
                <PesquisaHubspot
                    data={data}
                    setData={setData}
                    dataUser={dataUser}
                />
            </div>
            {data.contactoSelecionado != "" && (
                <div className='card grid gap-4'>
                    <ContactoHubspot
                        data={data}
                        setData={setData}
                        dataUser={dataUser}
                    />
                </div>
            )}

        </>
    );
}
