import React, { useState } from 'react';
import { Input, Tooltip, Switch } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export default function TaxaDeFinanciamento({ valoresTotais, setValoresTotais }) {


    const handleCredito = (checked) => {
        setValoresTotais((prevData) => ({
            ...prevData,
            taxaDeFinanciamentoAtivo: checked ? 1 : 0
        }));
        
    };


    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Crédito</p>
                </div>
            </div>
            <hr />
            <div>
                <label className='flex gap-2 items-center cursor-pointer'>
                    <Switch checked={valoresTotais.taxaDeFinanciamentoAtivo} onChange={handleCredito} />
                    <span>Esta proposta vai ter financiamento?</span>
                    <Tooltip title="A taxa de financiamento serve para cobrir a disponibilização de capital por parte da Importrust, que adiantará 80% do valor do carro até que este legalizado (altura em que a financeira do cliente disponibilizará o valor financiado).">
                        <AiOutlineInfoCircle className='icon-question' />
                    </Tooltip>
                </label>
            </div>

            {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                <div className='grid gap-3'>
                    <p>Comissão de disponibilização de capital</p>
                    <Input
                        placeholder="Comissão de disponibilização de capital"
                        value={valoresTotais.taxaDeFinanciamento}
                        onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            // Define 400 como o valor mínimo permitido
                            setValoresTotais((prevData) => ({
                                ...prevData,
                                taxaDeFinanciamento: value < 400 ? 400 : value
                            }));
                        }}
                        type="number"
                    />
                </div>
            )}
        </div>
    );
};

