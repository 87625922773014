import React, { useState } from 'react';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { FaCheck, FaLink } from 'react-icons/fa6';
import CopyIcon from '../Elements/CopyIcon';
import { RiRadioButtonLine } from "react-icons/ri";
import { MdOutlineExpandMore, MdOutlineKeyboardArrowUp } from "react-icons/md";
import DadosTecnicos from './DadosTecnicos';
import DuracaoDeProcesso from './DuracaoDeProcesso';
import EquipamentosViatura from './EquipamentosViatura';


export default function TabelaDadosCarroProposta({ dataUser, dataCarros, isvIucData, propostaInfo, valoresTotais }) {

    const { t } = useTranslation();


    return (
        <>
            <div className='flex flex-col gap-4 text-white font-semibold text-lg '>

                <div className='card grid gap-6'>

                    <div className="flex items-center gap-4">
                        {propostaInfo.propostaDisponivelOnline === 0 && (
                            <div className="rounded-full bg-[#156813] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                <RiRadioButtonLine />
                                <span className="ml-2">{t('tables.proposta_dispon_online')}</span>
                            </div>
                        )}

                        {propostaInfo.propostaDisponivelOnline === 1 && (
                            <div className="rounded-full bg-[#b63911] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                <RiRadioButtonLine />
                                <span className="ml-2">{t('tables.proposta_indispon_online')}</span>
                            </div>
                        )}

                        {propostaInfo.propostaExpirou === 1 && (
                            <div className="rounded-full bg-[#b63911] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                <RiRadioButtonLine />
                                <span className="ml-2">{t('tables.proposta_indispon_intern')}</span>
                            </div>
                        )}
                    </div>


                    <div className='grid grid-cols-1 md:grid-cols-1 gap-6 rounded-md'>
                        <div>
                            <Swiper
                                navigation={true}
                                modules={[Navigation]}
                            >
                                {dataCarros.imagens && dataCarros.imagens.length > 0 && dataCarros.imagens.map((imagem, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            style={{ borderRadius: '8px', objectFit: 'cover', width: '100%' }}
                                            src={imagem}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className='flex flex-col gap-6'>
                            <p className='font-medium text-2xl' style={{ overflowWrap: 'break-word' }}>
                                <b>{dataCarros.titulo}</b>
                            </p>

                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.quilometros')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.quantidade_de_km || `${t('tabela_viatura.sem_informacao')}`}</span></p>
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.data_registo')}: <br /><span className='text-lg text-white font-medium'>{isvIucData.dataPrimeiroRegisto || `${t('tabela_viatura.sem_informacao')}`}</span></p>
                                {isvIucData.cilindrada ? (
                                    <p className='text-base text-neutral-500'>
                                        {t('tabela_viatura.cilindrada')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {isvIucData.cilindrada} ccm
                                        </span>
                                    </p>
                                ) : null}
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.potencia')}: <br /><span className='text-lg text-white font-medium'>
                                    {dataCarros.potencia?.toString().includes('Hp') ? dataCarros.potencia?.toString().replace('Hp', 'CV') : dataCarros?.potencia + ' CV'}
                                </span></p>
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.combustivel')}: <br /><span className='text-lg text-white font-medium'>{isvIucData.tipoCombustivel || `${t('tabela_viatura.sem_informacao')}`}</span></p>
                                {isvIucData.tipoCombustivel !== "Eletrico" ? (
                                    <p className='text-base text-neutral-500'>
                                        {t('tabela_viatura.emissoes')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {isvIucData.emissoesCO2 || `${t('tabela_viatura.sem_informacao')}`}
                                        </span>
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className='text-white mais-informacoes-box'>
                        <h2 className='pt-2 pr-4 pb-2'>{t('tabela_viatura.mais_informacao')}</h2>
                        <List
                            size="small"
                            dataSource={[
                                { title: `${t('tabela_viatura.acidentes')}`, value: dataCarros.acidentes },
                                { title: `${t('tabela_viatura.tipo_de_caixa')}`, value: dataCarros.tipo_de_caixa },
                                { title: `${t('tabela_viatura.stand')}`, value: propostaInfo.standData.nome_stand },
                                { title: `${t('tabela_viatura.pais')}`, value: propostaInfo.standData.pais_stand },
                                { title: `${t('tabela_viatura.codigo_postal')}`, value: propostaInfo.standData.codigo_postal_stand },
                                { title: `${t('tabela_viatura.morada')}`, value: propostaInfo.standData.morada_stand },
                                { title: `${t('tabela_viatura.contacto')}`, value: propostaInfo.standData.contacto_stand },
                                { title: `${t('tabela_viatura.contacto_2')}`, value: propostaInfo.standData.contacto_2_stand },
                                { title: `${t('gerar_proposta.proposta_n')}`, value: propostaInfo.idProposta },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className='grid grid-cols-2 gap-4 w-full'>
                                        <p className='text-xs sm:text-sm text-white'>{item.title}:</p>
                                        <span className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>{item.value ? item.value : `${t('tabela_viatura.sem_informacao')}`}</span>
                                    </div>
                                </List.Item>
                            )}
                        />
                        <div className='grid grid-cols-2 gap-4 w-full border-t py-2'>
                            <p className='text-xs sm:text-sm text-white'>{t('tabela_viatura.ver_anuncio')}:</p>
                            <div className='flex items-center gap-4'>
                                <a href={propostaInfo.linkFora} target="_blank" className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>
                                    Link
                                    <FaLink />
                                </a>
                                <label className='text-white text-xs font-normal sm:text-sm flex items-center'>
                                    Copiar
                                    <CopyIcon text={propostaInfo.linkFora} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                {dataCarros.dados_tecnicos && (
                    <DadosTecnicos
                        dataCarros={dataCarros}
                    />
                )}

                {dataCarros.equipamento && (
                    <EquipamentosViatura
                        dataCarros={dataCarros}
                    />
                )}


                

            </div >
        </>
    );

};
