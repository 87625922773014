import React, { useState } from 'react';
import flagPortugal from '../../assets/flags/flag-portugal.svg';
import flagSpain from '../../assets/flags/flag-spain.svg';
import CustomTable from '../Tables/CustomTables';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp, FaEye } from 'react-icons/fa';
import CustomColumns from '../Tables/CustomColumns';
import CustomFilters from '../Tables/CustomFilters';
import { Button } from 'antd';
import { LuFilter } from 'react-icons/lu';


export default function TableAnalyticsUsers({ data, dataUser, filtersSelected, setFiltersSelected, loading }) {

    const { t } = useTranslation();

    const [drawerVisibleFilters, setDrawerVisibleFilters] = useState(false); // Open a drawer do Filtros da tabela das propostas

    const [configsTable, setConfigsTable] = useState({
        modalColunas: false,
    });

    // State to control visible columns
    const [visibleColumns, setVisibleColumns] = useState([
        'pais',
        'nome_utilizador',
        'propostas_mes_pesquisado',
        'media_propostas_diaria_mes_pesquisado',
        'propostas_aceites_mes_pesquisado',
        'media_taxa_servico_mes_pesquisado'
    ]);

    // console.log(data)

    const allColumns = [
        ...(dataUser.role === 'administrator' ? [{
            title: t('tables.pais'),
            dataIndex: 'pais',
            key: 'pais',
            render: (pais) => (
                pais === "pt" ? (
                    <img
                        src={flagPortugal}
                        alt="Portugal"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                ) : (
                    <img
                        src={flagSpain}
                        alt="Spain"
                        style={{ marginRight: '8px', width: '35px', height: '22px' }}
                    />
                )
            ),
            // sorter: (a, b) => a.pais.localeCompare(b.pais)
        }] : []),
        {
            title: t('tables.nome'),
            dataIndex: 'nome_utilizador',
            key: 'nome_utilizador',
            render: (text, record) => {

                const foto = record.foto_perfil
                const nome = record.nome_utilizador

                return (
                    <div className='flex items-center gap-2'>
                        <img src={foto} className='w-8 object-cover h-8 rounded-full' alt='User Profile' />
                        <span className='mr-1'>{nome}</span>
                    </div>
                );
            },
        },
        {
            title: `${t('tables.propostasmesatual')}`,
            key: 'propostas_mes_pesquisado',
            render: (text, record) => {
                // console.log(record)
                const diferencaPropostas = record.atual.propostas_geradas;
                const diferencaPropostasNumero = record.comparacoes.propostas_numero;

                let color = 'text-white'; // Default color
                let arrow = null;
                let symbol = null;

                const diferencaPropostasNumeroStr = String(diferencaPropostasNumero);

                // Set color and arrow based on the value
                if (diferencaPropostasNumeroStr.includes('-')) {
                    color = 'text-red-500';
                    arrow = <FaArrowDown />;
                    symbol = '-';
                } else {
                    color = 'text-green-500';
                    arrow = <FaArrowUp />;
                    symbol = '+';
                }

                // Conditionally hide the percentage and arrow if 0 or 100
                const showPercentage = diferencaPropostasNumero !== 0 && diferencaPropostasNumero !== -100;

                return (
                    <div className='flex items-center gap-2'>
                        {diferencaPropostas}
                        {showPercentage && (
                            <>
                                {/* {arrow} */}
                                <span className={`${color} mr-1 font-bold`}>
                                    {diferencaPropostasNumeroStr}
                                </span>
                            </>
                        )}
                    </div>
                );
            },
            sorter: (a, b) => a.atual.propostas_geradas - b.atual.propostas_geradas
        },
        {
            title: `${t('tables.propostasmediadiaria')}`,
            key: 'media_propostas_diaria_mes_pesquisado',
            render: (text, record) => {
                const diferencaPropostas = record.atual.media_propostas_diaria;

                return (
                    <div className='flex items-center gap-2'>
                        <span className='mr-1'>{diferencaPropostas}</span>
                    </div>
                );
            },
            sorter: (a, b) => a.atual.media_propostas_diaria - b.atual.media_propostas_diaria
        },

        ...(dataUser.language === 'pt' ? [{
            title: 'Propostas Aceites',
            dataIndex: 'propostas_aceites_mes_pesquisado',
            key: 'propostas_aceites_mes_pesquisado',
            render: (text, record) => {
                const diferencaPropostas = record.atual.propostas_aceites;

                return (
                    <div className='flex items-center gap-2'>
                        <span className='mr-1'>{diferencaPropostas}</span>
                    </div>
                );
            },
            sorter: (a, b) => a.atual.propostas_aceites - b.atual.propostas_aceites,
        }] : []),

        {
            title: t('tables.mediataxadeservico'),
            dataIndex: 'media_taxa_servico_mes_pesquisado',
            key: 'media_taxa_servico_mes_pesquisado',
            render: (text, record) => (
                <span>
                    {record.atual.media_taxa_servico !== 0
                        ? `≈ ${record.atual.media_taxa_servico} €`
                        : '≈ 0 €'}
                </span>
            ),
            sorter: (a, b) =>
                a.atual.media_taxa_servico - b.atual.media_taxa_servico,
        },
    ];


    //Gestão das Colunas visiveis e escondidas
    const columns = allColumns.filter(col => visibleColumns.includes(col.key));


    //Abrir popup para gerir as Colunas
    const showModal = () => {
        setConfigsTable((prevState) => ({
            ...prevState,
            modalColunas: true,  // Define modalColunas como false
        }));
    };


    // Função para abrir o Drawer dos Filtros da tabela das propostas
    const showDrawerFilters = () => {
        setDrawerVisibleFilters(true);
    };

    // Defina os filtros específicos para esta tabela
    const tablePropostasFilters = [
        {
            type: 'select',
            label: 'Business Developer',
            key: 'id_criou',
        },
        ...(dataUser.role === 'administrator' ? [
            {
                type: 'select',
                label: t('tables.pais'),
                key: 'pais',
            }
        ] : []),
    ];

    return (
        <>

            <div className='flex justify-between items-center gap-4'>
                <p className='text-white text-sm font-medium'>{t('dashboard.resumo_mensal')}</p>

                {dataUser.cargo_select !== 'business_developer' && (
                    <div className='flex justify-end gap-4'>
                        <Button
                            type="primary"
                            onClick={showDrawerFilters}
                            className='shadow-none font-bold text-black bg-white'>
                            <div className='flex items-center gap-2'>
                                <LuFilter />
                                <p>{t('propostas.filtros')}</p>
                            </div>
                        </Button>
                        <Button
                            type="primary"
                            onClick={showModal}
                            className='shadow-none font-bold text-black bg-white'>
                            <div className='flex items-center gap-2'>
                                <FaEye size={20} style={{ cursor: 'pointer' }} />
                                <p>{t('propostas.colunas')}</p>
                            </div>
                        </Button>
                    </div>
                )}
            </div>

            <CustomTable
                columns={columns}
                data={data.map((record, index) => ({ ...record, key: record.id || index }))}
                pagination={false}
                loading={loading}
            />

            <CustomColumns
                allColumns={allColumns}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                configsTable={configsTable}
                setConfigsTable={setConfigsTable}
            />

            <CustomFilters
                filters={tablePropostasFilters}
                filtersSelected={filtersSelected}
                setFiltersSelected={setFiltersSelected}
                drawerVisibleFilters={drawerVisibleFilters}
                setDrawerVisibleFilters={setDrawerVisibleFilters}
                dataUser={dataUser}
            />
        </>
    )
};
