import React from 'react';
import { Drawer } from 'antd';
import TodosCamposGerarDocumentos from '../GerarDocumentos/TodosCamposGerarDocumentos';

export default function DrawerEditarDocumentosGerados({ data, setData, selectedDocumento, setSelectedDocumento, setIsDrawerVisibleEditarDocumento, isDrawerVisibleEditarDocumento, dataUser }) {

    // Função para abrir o Drawer dos Filtros
    const closeDrawerEditarDocumento = () => {
        setIsDrawerVisibleEditarDocumento(false);
        setSelectedDocumento(null);
    };

    return (
        <Drawer
            title="Editar Documento"
            placement="right"
            className='detalhes-de-log-email'
            open={isDrawerVisibleEditarDocumento}
            onClose={closeDrawerEditarDocumento}
        >
            {/* Aqui você pode adicionar o conteúdo e os campos do Drawer para edição do documento */}
            {selectedDocumento && (
                <div>
                    {Object.keys(data.fields).length > 0 && (
                        <>
                            <TodosCamposGerarDocumentos
                                fields={data.fields}
                                otherDocs={data.otherDocs}
                                data={data}
                                setData={setData}
                                dataUser={dataUser}
                                white={true}
                            />
                        </>
                    )}

                </div>
            )}
        </Drawer>
    );
};

