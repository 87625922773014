import React from 'react';
import { Link } from 'react-router-dom';

export default function CustomFooter({ dataUser }) {


  return (
    <div className='p-4 text-center text-xs text-neutral-500 flex justify-between'>
      <div className='flex items-center gap-2'>
        <span>Importrust | Version {process.env.REACT_APP_VERSION}</span>
        <span>|</span>
        <a href='https://changelog.importrust.com' target='_blank' className='underline'>Changelog</a>
      </div>
      
      {dataUser.iDTokenAuthenticated == 1 && (
        <Link to="/logs-devs" className='underline'>Logs Devs</Link>
      )} 

    </div>
  );
};

