import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const InputCalculoProposta = ({ urlCarroLink, setUrlCarroLink }) => {

    const { t } = useTranslation();

    return (
        <div className='grid gap-4'>
            <div className='grid gap-1 text-white'>
                <p className='text-lg font-bold'>{t('simulador.titulo_principal')}</p>
                <p className='text-sm font-normal'>{t('simulador.sub_titulo')}</p>
            </div>
            <hr></hr>
            <Input
                placeholder={t('simulador.pesquisa')}
                value={urlCarroLink}
                onChange={(e) => setUrlCarroLink(e.target.value)}
            />
        </div>
    );
};

export default InputCalculoProposta;
