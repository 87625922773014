import React from 'react';
import { useTranslation } from 'react-i18next';
import TableTodasPropostas from '../../components/Propostas/TableTodasPropostas';
import TitleSection from '../../components/Elements/Title';

export default function TodasAsPropostas({ dataUser }) {

    const { t } = useTranslation();


    return (
        <div className='card grid gap-4'>
            <TitleSection
                title={t('tables.titulo_principal')}
                description={t('propostas.sub_titulo')}
            />
            <TableTodasPropostas dataUser={dataUser} />
        </div>
    );
};

