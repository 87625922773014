import React, { useState, useEffect } from 'react';
import ISVComponent from './compISV';
import PrecoChaveNaMaoProposta from './precoChaveNaMao';
import IUCComponent from './compIUC';
import { useTranslation } from 'react-i18next';
import ExpressInfoModal from '../ExpressInfoDrawer';
import { Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export default function PropsPrecoFinalProposta({ valoresTotais, incluirIVA, setValoresTotais, activeTabKey, dataUser, formatarNumero }) {


    const { t } = useTranslation();


    useEffect(() => {

        const precoCaucaoComIva = valoresTotais.valorPrecoChaveNaMaoParticular * 0.2
        const precoCaucaoSemIva = valoresTotais.valorPrecoChaveNaMaoEmpresa * 0.2
        const valorTotalFinalFinaciamento = activeTabKey === "1" ? precoCaucaoComIva : precoCaucaoSemIva;


        const calculoCaucaoFinaciamento = Number.isNaN(valorTotalFinalFinaciamento) ? 0 : valorTotalFinalFinaciamento
        const calculoValorIVA = valoresTotais.precoViaturaComIva - valoresTotais.precoViaturaSemIva


        if (
            valoresTotais.caucaoFinanciamento !== calculoCaucaoFinaciamento ||
            valoresTotais.caucaoIvaPaisOrigem !== calculoValorIVA
        ) {
            setValoresTotais((prevData) => ({
                ...prevData,
                caucaoFinanciamento: calculoCaucaoFinaciamento,
                caucaoIvaPaisOrigem: calculoValorIVA,
            }));
        }


    }, [valoresTotais]);


    return (
        <div className='flex flex-col gap-1'>
            {/* Preço Viatura com IVA (apenas para Particular) */}
            {incluirIVA && (
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                        <p className='text-black text-base'>{t('gerar_proposta.valor_viatura')}</p>
                    </div>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.precoViaturaComIva)}
                    </p>
                </div>
            )}

            {/* Preço Viatura sem IVA */}
            {!incluirIVA && (
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                        <p className='text-black text-base'>{t('gerar_proposta.valor_viatura')}</p>
                        {valoresTotais.validaSeValorNaoTemIva == 1 && (
                            <Tooltip title={t('gerar_proposta.i_no_valor_viatura')}>
                                <AiOutlineInfoCircle className='icon-question' />
                            </Tooltip>
                        )}
                    </div>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.precoViaturaSemIva)}
                    </p>
                </div>
            )}


            {dataUser.language == "pt" && !incluirIVA && valoresTotais.validaSeValorNaoTemIva == 1 && (
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                        <p className='text-black text-base'>{t('gerar_proposta.caucao_iva')}</p>
                        <Tooltip title={t('gerar_proposta.i_caucao_iva')}>
                            <AiOutlineInfoCircle className='icon-question' />
                        </Tooltip>
                    </div>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.caucaoIvaPaisOrigem)}
                    </p>
                </div>
            )}


            {/* Valor Total ISV */}
            <ISVComponent
                dataUser={dataUser}
                valoresTotais={valoresTotais}
                formatarNumero={formatarNumero}
            />


            {/* Taxa de Serviço */}
            <div className='flex justify-between items-center'>
                <p className='text-black text-base'>{t('gerar_proposta.taxa_servico')}</p>
                <p className='font-bold text-lg'>
                    {formatarNumero(valoresTotais.taxaDeServico)}
                </p>
            </div>

            {/* Taxa de Financiamento */}
            {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                <div className='flex justify-between items-center'>
                    <p className='text-black text-base'>{t('gerar_proposta.taxa_financiamento')}</p>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.taxaDeFinanciamento)}
                    </p>
                </div>
            )}

            {/* Taxa de Serviço Express */}
            {valoresTotais.valorExpressAtivo == 1 && (
                <div className='flex justify-between items-center'>
                    <span className='text-base flex items-center gap-2'>
                        Express
                        <ExpressInfoModal />
                    </span>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.valorExpress)}
                    </p>
                </div>
            )}

            {/* Preço Chave na Mão */}
            <PrecoChaveNaMaoProposta
                activeTabKey={activeTabKey}
                valoresTotais={valoresTotais}
                setValoresTotais={setValoresTotais}
                formatarNumero={formatarNumero}
                incluirIVA={incluirIVA}
                dataUser={dataUser}
                // validaSeValorNaoTemIva={validaSeValorNaoTemIva}
            />


            {dataUser.language == "pt" && valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                        <p className='text-black text-base'>Caução Financiamento</p>
                        <Tooltip title="Valor dos 20% do total do negócio serve como sinal para a Importrust.">
                            <AiOutlineInfoCircle className='icon-question' />
                        </Tooltip>
                    </div>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.caucaoFinanciamento)}
                    </p>
                </div>
            )}


            <hr></hr>

            {/* Valor Total IUC */}
            <IUCComponent
                formatarNumero={formatarNumero}
                dataUser={dataUser}
                valoresTotais={valoresTotais}
            />


        </div >
    );
};