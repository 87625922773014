import React from 'react';
// import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const NotificationItem = ({ subject, message, link, notificacoes, index }) => (
    <div className={`grid gap-1 notification-item p-2 border-b ${index === notificacoes.length - 1 ? 'border-b-0' : ''}`}>
        <p className="font-bold">{subject}</p>
        {/* <p dangerouslySetInnerHTML={{ __html: message }}/> */}
        {/* {link && (
            <a href={link} className="text-black hover:text-black underline hover:underline" target="_blank" rel="noopener noreferrer">
                Ver mais
            </a>
        )} */}
    </div>
);

export default function PopupNotificacoes({ notificacoes }) {

    const { t } = useTranslation();

    return (
        <div className="popup-notificacoes max-w-[450px] grid gap-2">
            <p className='text-lg font-bold'>Notificações</p>
            <hr />
            <div>
                {notificacoes.map((notification, index) => (
                    <NotificationItem
                        key={notification.id_notificacao}
                        notificacoes={notificacoes}
                        index={index}
                        subject={notification.assunto}
                        message={notification.mensagem}
                        link={notification.link}
                    />
                ))}
            </div>
            <div>
                <Link to={`/${t('menu.notificacoes')
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                    .replace(/\s+/g, '-')}`}  // Corrigido para substituir múltiplos espaços
                >
                    <Button
                        type="primary"
                        target="_blank"
                        className="flex text-center gap-2 justify-center items-center bg-white text-black font-bold rounded w-full"
                    >
                        Ver Todas
                    </Button>
                </Link>

            </div>
        </div>
    );
}
