import React, { useEffect, useState } from "react";
import TitleSection from "../../components/Elements/Title";
import axios from "axios";
import { Button, message } from "antd";
import FormSelectsGerarDocumentos from "../../components/GeradorDocumentos/GerarDocumentos/FormSelectsGerarDocumentos";
import TodosCamposGerarDocumentos from "../../components/GeradorDocumentos/GerarDocumentos/TodosCamposGerarDocumentos";
import { Link } from "react-router-dom";
import Loading from "../../components/Elements/Loading";

export default function GerarDocumentos({ dataUser }) {


    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        tipos_documentos: [],
        tipos_sociedades: [],
        tipos_contratos: [],
        fields: {},
        otherDocs: {},
        linkDownload: []
    });


    useEffect(() => {
        const fetchData = async () => {

            let url = `${process.env.REACT_APP_GET_SELECT_DOCUMENTOS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}`;

            try {
                const response = await axios.get(url);
                if (response.data.sucesso) {
                    console.log(response.data)
                    setData((prevState) => ({
                        ...prevState,
                        tipos_documentos: response.data.data.tipos_documentos,
                        tipos_sociedades: response.data.data.tipos_sociedades,
                        tipos_contratos: response.data.data.tipos_contratos,
                        fields: {},
                        otherDocs: {}
                    }));
                } else {
                    message.error(response.data.mensagem);
                }
            } catch (error) {
                console.error('Error occurred:', error);
                message.error('Ocorreu um erro ao procurar pelos dados.');
            }
        };

        fetchData();
    }, [dataUser]);


    const handleFormChange = (updatedData) => {
        setData((prevState) => ({
            ...prevState,
            ...updatedData,
            linkDownload: []
        }));
    };


    return (
        <>
            <div className='card grid gap-4'>
                <TitleSection title='Gerar Documentos' description="Gera o documento de forma rápida - Versão em fase de testes." />
                <FormSelectsGerarDocumentos setLoading={setLoading} data={data} dataUser={dataUser} onFormChange={handleFormChange} setData={setData} />
                {loading && (
                    <Loading />
                )}
                {Object.keys(data.fields).length > 0 && (
                    <>
                        <hr className="mb-[24px]" />
                        <TodosCamposGerarDocumentos fields={data.fields} otherDocs={data.otherDocs} data={data} setData={setData} dataUser={dataUser} white={false} />
                    </>
                )}
            </div>
            <div className="grid items-center justify-items-center gap-4">
                <p className="text-white text-base font-semibold">Se encontrares algum problema usa a versão antiga!</p>
                <Link to="/gerador-documentos-old">
                    <Button
                        type="primary"
                        className='shadow-none font-bold text-black bg-yellow-500'>
                        <div className='flex items-center gap-2'>
                            <p>Abrir</p>
                        </div>
                    </Button>
                </Link>
            </div>
        </>
    );
}
