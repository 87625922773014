import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Popover, Menu, Avatar, Divider } from "antd";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";

export default function UserPopover({ dataUser }) {

    const { t } = useTranslation();

    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    })


    const [showButton, setShowButton] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = "/";
    };


    return (
        <>
            <Popover
                placement="bottomRight"
                content={
                    <div className=" w-64 perfil-menu">
                        <div className='p-2 flex flex-col items-center gap-2'>
                            <Avatar className='m-1 bg-white' size={80} src={dataUser.userData?.data?.foto_perfil} />
                            <span className='text-base font-semibold'>{dataUser.userData?.data?.display_name}</span>
                            <span className='text-sm'>{dataUser.userData?.data?.cargo}</span>
                        </div>
                        <Divider dashed />
                        <Menu>
                            <Menu.Item>
                                <Link
                                    to={`/${t('menu.notificacoes')
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                                        .replace(/ /g, '-')}`}  // Replace spaces with dashes
                                >
                                    {t('menu.lista_notificacoes')}
                                </Link>
                            </Menu.Item>
                        </Menu>
                        <Divider dashed />
                        <Menu>
                            <Menu.Item onClick={handleLogout}>
                                {t('logout')}
                            </Menu.Item>
                        </Menu>
                    </div>
                }
                trigger="click"
            >
                <div className="flex gap-2 items-center cursor-pointer text-white">
                    {/* {!isMobile && (

                        <div className="flex flex-col">
                            <span className="text-xs font-semibold">{dataUser.userData?.data?.display_name}</span>
                            <span className="text-xs">{dataUser?.userData?.data?.cargo}</span>
                        </div>
                    )} */}
                    <Avatar className='m-1 bg-white' size={37} src={dataUser?.userData?.data?.foto_perfil} />
                </div>
            </Popover>
        </>
    );
};

