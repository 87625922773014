import React, { useState } from 'react';
// import { Tabs } from 'antd';
// import Loading from '../../Elements/Loading';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DocumentTabs from './DocumentTabs';
import DownloadDocumentos from './DownloadDocumentos';
dayjs.extend(customParseFormat);


export default function TodosCamposGerarDocumentos({ fields, data, setData, dataUser, white }) {

    const [loading, setLoading] = useState(false);  // Estado de loading para gerar pdf

    return (
        <>
            <div className={`grid`}>
                <DocumentTabs
                    documents={fields}
                    white={white}
                    dataUser={dataUser}
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                />
            </div>

            <DownloadDocumentos
                loading={loading}
                data={data}
            />

        </>
    );
}
