import React from 'react';

export default function ResultadoTotal({ titulo, valor, bgColor, textColor = 'text-white' }) {
    return (
        <div className={`grid gap-2 grid-cols-1 md:grid-cols-1 rounded ${bgColor} p-2`}>
            <p className={`flex items-center gap-2 text-base ${textColor}`}>
                {titulo}
            </p>
            <p className="text-lg text-white font-bold">
                {valor ? `${valor}` : `0 €`}
            </p>
        </div>
    );
}
