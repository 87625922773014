import React from 'react';
import { Checkbox, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export default function CustomColumns ({ allColumns, configsTable, setConfigsTable, visibleColumns, setVisibleColumns }) {

    const { t } = useTranslation();

    //Fechar Modal
    const handleOk = () => {
        setConfigsTable((prevState) => ({
            ...prevState,
            modalColunas: false,  // Define modalColunas como false
        }));
    };

    //Fechar Modal
    const handleCancel = () => {
        setConfigsTable((prevState) => ({
            ...prevState,
            modalColunas: false,  // Define modalColunas como false
        }));
    };

    //Selecionar Coluna
    const handleColumnChange = (checkedValues) => {
        setVisibleColumns(checkedValues);
    };


    return (
        <Modal
            title={t('tables.config_da_tabela')}
            open={configsTable.modalColunas}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
        >
            <Checkbox.Group
                style={{ width: '100%' }}
                className='grid grid-cols-2 gap-4'
                value={visibleColumns}
                onChange={handleColumnChange}
            >
                {allColumns.map(column => (
                    <Checkbox key={column.key} value={column.key}>
                        {column.title}
                    </Checkbox>
                ))}
            </Checkbox.Group>
        </Modal>
    );
};

