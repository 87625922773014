import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Tooltip, message } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import AssociarPartilharProposta from '../../../components/PerfilProposta/Associar_e_PartilharProposta';
import TabelaDadosCarroProposta from '../../../components/CalculoProposta/TabelaDadosDoCarro';
import InfoPerfilClienteHubspotProposta from '../../../components/PerfilProposta/HubspotProposta/InfoPerfilClienteHubspotProposta';
import FormCamposCalculoProposta from '../../../components/PerfilProposta/PT/FormCamposCalculoProposta_ISV_IUC';
import PrecoFinalProposta from '../../../components/CalculoProposta/PrecoFinalProposta';
import Loading from '../../../components/Elements/Loading';
import FasesDaProposta from '../../../components/PerfilProposta/PerfilProposta/FasesDaProposta';
import EstimativaDeComissao from '../../../components/CalculoProposta/EstimativaDeComissao';
import DuracaoDeProcesso from '../../../components/CalculoProposta/DuracaoDeProcesso';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

export default function PerfilDaPropostaPT({ dataUser }) {

    const [loading, setLoading] = useState(true);
    const [dataCarros, setDataCarros] = useState([]);


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };


    // const captureDivAsImageOrPdf = async (format) => {
    //     const div = document.getElementById('content-to-capture');
    //     if (!div) return;

    //     // Ocultar elementos indesejados
    //     const elementsToHide = document.querySelectorAll('.hide-on-capture');
    //     elementsToHide.forEach(el => el.style.display = 'none');

    //     // Aplicar fundo preto
    //     div.style.backgroundColor = 'black';

    //     try {
    //         const canvas = await html2canvas(div, {
    //             useCORS: true,
    //             scale: 3, // Aumenta a resolução
    //             scrollX: -window.scrollX,
    //             scrollY: -window.scrollY,
    //             windowWidth: div.offsetWidth,
    //             windowHeight: div.offsetHeight,
    //         });

    //         if (format === 'image') {
    //             const link = document.createElement('a');
    //             link.download = 'screenshot.png';
    //             link.href = canvas.toDataURL('image/png');
    //             link.click();
    //         } else if (format === 'pdf') {
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new jsPDF({
    //                 orientation: 'portrait',
    //                 unit: 'px',
    //                 format: [canvas.width, canvas.height],
    //             });

    //             pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    //             pdf.save('document.pdf');
    //         }
    //     } catch (error) {
    //         console.error('Erro ao capturar o conteúdo:', error);
    //         message.error('Erro ao gerar o arquivo. Tente novamente.');
    //     } finally {
    //         // Restaurar os elementos escondidos
    //         elementsToHide.forEach(el => el.style.display = '');
    //         div.style.backgroundColor = ''; // Remove o fundo preto aplicado temporariamente
    //     }
    // };


    // const handleDownloadImage = () => {
    //     const element = document.getElementById('print'),
    //     canvas = await html2canvas(element),
    //     data = canvas.toDataURL('image/jpg'),
    //     link = document.createElement('a');

    //     link.href = data;
    //     link.download = 'downloaded-image.jpg';

    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   };


    const [propostaInfo, setPropostaInfo] = useState({
        linkFora: '',
        linkDaProposta: '',
        estadoDaProposta: '',
        idProposta: '',
        standData: [],
        tipoNegocio: '',
        tipoProposta: '',
        outros: [],
        propostaExpirou: null,
        propostaDisponivelOnline: null
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
        valorTotalISV: 0,
        valorTotalIUC: 0,
        valorTotalISV2025: null
    });

    //Valores Totais
    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        taxaDeServico: 3000,
        taxaDeFinanciamento: 400,
        taxaDeFinanciamentoAtivo: 0,
        caucaoFinanciamento: 0,
        valorExpress: 1000,
        valorExpressAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        valorTotalISV2025: null,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        caucaoIvaPaisOrigem: 0,
        compsIUC: {
            iucCilindrada: 0,
            iucCO2: 0,
            iucAdicionalCO2: 0,
            iucCoeficienteAnoMatriculo: 0,
            iucAdicionalGasoleo: 0
        },
        compsISV: {
            isvcomponentecilindrada: 0,
            isvcomponenteambiental: 0,
            isvReducaoAnosUsoCilindrada: 0,
            isvReducaoAnosUsoAmbiental: 0,
            isvAgravamentoParticulas: 0,
            isvReducaoAnosUsoParticulas: 0,
            isvTaxaAplicavelTabela: '',
        }
    });

    const [hubspotInfo, setHubspotInfo] = useState({
        idDoDeal: '',
        nomeDoClienteDeal: '',
        emailDoClienteDeal: '',
        etapaDoDeal: '',
        pipelineDoDeal: '',
    });


    // Parsea o nome da proposta da URL usando URLSearchParams
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search); // Usa URLSearchParams.
        const nomeProposta = searchParams.get('n'); // Obtém o nome da proposta.
        const isNew = searchParams.get('new') === 'true'; // Obtém o parametro só para mostrar a notificação de proposta criada.

        if (isNew) {
            message.success('Proposta criado com sucesso!')
        }

        if (nomeProposta) {
            // Definir a URL de requisição com base no nomeProposta e dados do usuário
            const requestUrl = `${process.env.REACT_APP_GET_PROPOSTA_PT}?n=${nomeProposta}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;

            // Inicia o carregamento
            setLoading(true);

            // Faz a requisição à API usando Axios
            axios.get(requestUrl)
                .then((response) => {

                    if (response.data.sucesso) {

                        const allDataCarro = response.data.data.carro;
                        const allHubspot = response.data.data.proposta.hubspot;
                        const allValores = response.data.data.simulacao;
                        const allValoresExtra = response.data.data.simulacao_extras;
                        const allProposta = response.data.data.proposta;
                        const allStand = response.data.data.stand;


                        setDataCarros(allDataCarro);// Atualiza os dados dos carros com a resposta

                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            linkFora: allProposta.link_proposta,
                            linkDaProposta: allProposta.link_proposta_view,
                            estadoDaProposta: allProposta.estado_proposta_hubspot,
                            tipoNegocio: allProposta?.tipo_contrato || "",
                            tipoProposta: allProposta?.tipo_proposta,
                            idProposta: allProposta.id_proposta,
                            standData: allStand,
                            outros: allProposta,
                            propostaExpirou: allProposta.expirou,
                            propostaDisponivelOnline: allProposta.indisponivel_online
                        }));

                        setIsvIucData((prevData) => ({
                            ...prevData,
                            cilindrada: allValores.cilindrada,
                            novoUsado: allValores.novo,
                            tipoCombustivel: allValores.tipo_de_combustivel,
                            emissoesCO2: allValores.emissoes_numerico,
                            hibridoPlugIn: allValores.hibrido,
                            wltp: allValores.wltp,
                            dataPrimeiroRegisto: allValores.data_registo,
                            valorTotalISV: allValores.isv,
                            valorTotalIUC: allValores.iuc,
                            valorTotalISV2025: allValores.isv_2025,
                        }));


                        setValoresTotais((prevData) => ({
                            ...prevData,
                            precoViaturaComIva: allDataCarro.preco_final_carro,
                            precoViaturaSemIva: allDataCarro.preco_final_carro_sem_iva,
                            taxaDeServico: allValores.taxadeservico,
                            taxaDeFinanciamento: allValores.comissao_credito,
                            taxaDeFinanciamentoAtivo: allValores.check_comissao_credito,
                            caucaoFinanciamento: allValores.caucao_comissao_credito,
                            valorExpress: allValores.comissao_express,
                            valorExpressAtivo: allValores.check_comissao_express,
                            comissao: allValores.comissaodeservico,
                            valorTotalISV: allValores.isv,
                            valorTotalIUC: allValores.iuc,
                            valorTotalISV2025: allValores.isv_2025,
                            valorPrecoChaveNaMaoParticular: allValores.valor_chavenamao_particular,
                            valorPrecoChaveNaMaoEmpresa: allValores.valor_chavenamao_empresa,
                            caucaoIvaPaisOrigem: allValores.caucao_iva_pais_origem,
                            compsIUC: {
                                iucCilindrada: allValoresExtra.iuc_cilindrada,
                                iucCO2: allValoresExtra.iuc_co2,
                                iucAdicionalCO2: allValoresExtra.iuc_adicional_co2,
                                iucCoeficienteAnoMatriculo: allValoresExtra.iuc_coeficiente_ano_matriculo,
                                iucAdicionalGasoleo: allValoresExtra.iuc_adicional_gasoleo,
                            },
                            compsISV: {
                                isvcomponentecilindrada: allValoresExtra.isv_componente_cilindrada,
                                isvcomponenteambiental: allValoresExtra.isv_componente_ambiental,
                                isvReducaoAnosUsoCilindrada: allValoresExtra.isv_reducao_anos_uso_cilindrada,
                                isvReducaoAnosUsoAmbiental: allValoresExtra.isv_reducao_anos_uso_ambiental,
                                isvAgravamentoParticulas: allValoresExtra.isv_agravamento_particulas,
                                isvReducaoAnosUsoParticulas: allValoresExtra.isv_reducao_anos_uso_particulas,
                                isvTaxaAplicavelTabela: allValoresExtra.isv_taxa_aplicavel_tabela,
                            }
                        }));

                        if (allHubspot?.deal) {
                            setHubspotInfo((prevData) => ({
                                ...prevData,
                                idDoDeal: allHubspot.deal.id_deal_hubspot,
                                nomeDoClienteDeal: allHubspot.contacto.firstname,
                                emailDoClienteDeal: allHubspot.contacto.email,
                                etapaDoDeal: allHubspot.deal.dealstage_name,
                                pipelineDoDeal: allHubspot.deal.pipeline_name,
                            }));
                        }

                    }


                })
                .catch((error) => {
                    console.error('Erro ao buscar dados da proposta:', error); // Trata erros
                })
                .finally(() => {
                    setLoading(false); // Para o loading depois da requisição
                });
        }
    }, [dataUser.iDTokenAuthenticated]); // Removi nomeProposta das dependências, pois é tratado internamente


    return (
        <div className='w-full flex flex-col justify-center items-center gap-4'>
            {/* Exibe o loading enquanto os dados estão sendo carregados */}
            {loading ? (
                <Loading />
            ) : (
                <>
                    {/* Exibe os dados do carro se existirem */}
                    {dataCarros ? (
                        <div id="content-to-capture" className="w-full text-white grid gap-4">
                            <div>
                                <div className='card grid gap-4'>
                                     <AssociarPartilharProposta
                                        propostaInfo={propostaInfo}
                                        dataUser={dataUser}
                                        dataCarros={dataCarros}
                                        setHubspotInfo={setHubspotInfo}
                                        hubspotInfo={hubspotInfo}
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        setPropostaInfo={setPropostaInfo}
                                        isvIucData={isvIucData}
                                        setIsvIucData={setIsvIucData}
                                        formatarNumero={formatarNumero}
                                    /> 
                                </div>
                            </div>


                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                <div className='w-full sm:w-3/5'>
                                    {/* Tabela com os dados do carro */}
                                    <TabelaDadosCarroProposta
                                        dataCarros={dataCarros}
                                        isvIucData={isvIucData} 
                                        urlCarroLink={dataCarros.urlCarroLink}
                                        dataUser={dataUser}
                                        propostaInfo={propostaInfo}
                                        valoresTotais={valoresTotais}
                                    />
                                </div>

                                <div className="w-full sm:w-2/5 flex flex-col gap-4">

                                    {propostaInfo.estadoDaProposta !== "nao_associada" && (
                                        <FasesDaProposta
                                            propostaInfo={propostaInfo}
                                        />
                                    )}

                                    {hubspotInfo.idDoDeal !== "" && (
                                        <InfoPerfilClienteHubspotProposta
                                            dataUser={dataUser}
                                            dataCarros={dataCarros}
                                            setHubspotInfo={setHubspotInfo}
                                            hubspotInfo={hubspotInfo}
                                            setPropostaInfo={setPropostaInfo}
                                            propostaInfo={propostaInfo}
                                        />
                                    )}

                                    <FormCamposCalculoProposta
                                        isvIucData={isvIucData}
                                    />

                                    <PrecoFinalProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    /> 

                                    {propostaInfo.outros.estimativa_entrega && (
                                        <DuracaoDeProcesso
                                            valoresTotais={valoresTotais}
                                            propostaInfo={propostaInfo}
                                        />
                                    )}

                                    <EstimativaDeComissao
                                        valoresTotais={valoresTotais}
                                        formatarNumero={formatarNumero}
                                    />

                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
}
