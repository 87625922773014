import React from 'react';

export default function FormCamposCalculoPropostaES({ isvIucData }) {

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Calculo del IDM</p>
                </div>
            </div>
            <hr />

            <div className="grid gap-4">
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-white'>

                    {/* Exibe Provincias */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Provincia</p>
                        <p className='text-base'>{isvIucData.nomeProvincia || 'Sin información'}</p>
                    </div>


                    {/* Exibe Munícipio */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Munícipio</p>
                        <p className='text-base'>{isvIucData.nomeMunicipio || 'Sin información'}</p>
                    </div>



                    {/* Exibe Marca */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Marca</p>
                        <p className='text-base'>{isvIucData.nomeMarca || 'Sin información'}</p>
                    </div>


                    {/* Exibe Modelo */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Modelo</p>
                        <p className='text-base'>{isvIucData.nomeModelo || 'Sin información'}</p>
                    </div>


                    {/* Exibe Cilindrada */}
                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Cilindrada</p>
                            <p className='text-base'>{isvIucData.cilindrada || 'Sin información'}</p>
                        </div>
                    )}

                    {/* Exibe Estado */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Nuevo / Usado</p>
                        <p className='text-base'>{isvIucData.novoUsado == "1" ? "Nuevo" : "Usado"}</p>
                    </div>

                    {/* Exibe Fecha de primer registro */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Fecha de primer registro</p>
                        <p className='text-base'>{isvIucData.dataPrimeiroRegisto || 'Sin información'}</p>
                    </div>

                    {/* Exibe Valor del Vehículo Nuevo */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Valor del Vehículo Nuevo</p>
                        <p className='text-base'>{isvIucData.valorViaturaNova ? `${formatarNumero(isvIucData.valorViaturaNova)}` : 'Sin información'}</p>
                    </div>


                    {/* Exibe Tipo de Combustível */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Tipo de Combustível</p>
                        <p className='text-base'>{isvIucData.tipoCombustivel || 'Sin información'}</p>
                    </div>

                    {/* Exibe Emissões CO2 */}
                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Emisiones de Gases CO2</p>
                            <p className='text-base'>{isvIucData.emissoesCO2 || 'Sin información'}</p>
                        </div>
                    )}

                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Híbrido Enchufable</p>
                            <p className='text-base'>{isvIucData.hibridoPlugIn ? 'Sí' : 'No'}</p>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};
