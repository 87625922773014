import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../components/Elements/Title';
import TableListaDeUtilizadores from '../../components/User/TableListaDeUtilizadores';

export default function ListaDeUtilizadores({ dataUser }) {

    const { t } = useTranslation();


    return (
        <div className='card grid gap-4'>
            <TitleSection
                title={t('lista_utilizadores.titulo_principal')}
                description={''}
            />
            <TableListaDeUtilizadores dataUser={dataUser} />
        </div>
    );
};

